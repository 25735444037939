.admin-users-wrap {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.admin-users__modal-input-wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.admin-users__modal-input-date-wrap {
}

.admin-users__modal-input-date-wrap .MuiFormHelperText-root {
  font-family: 'Montserrat';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--customColor) !important;
}

.admin-users {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 15px;
}

.admin-users__main-wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 0px 0px;
  border-radius: 10px;
}

.admin-users__btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.admin-users__add-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.admin-users__users--wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.admin-users__users-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: calc(100dvh - 324px);
  overflow: auto;
  margin-top: 15px;
}

.admin-users__users-wrap--is-active {
  max-height: calc(100dvh - 263px);
}

.admin-users__users {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.admin-users__users-header-wrap {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  display: none;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  min-width: 830px;
  color: var(--customColor);
  font-size: 14px;
  font-weight: 400;
  background-color: #ffffff;
}

.admin-users__users-header-name-wrap {
  flex: 1 1;
  width: 250px;
  min-width: 250px;
}

.admin-users__users-header-name {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.7px;
  padding-left: 88px;
}

.admin-users__users-header-training {
  flex: 1 1;
  min-width: 120px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.7px;
}

.admin-users__users-header-finish {
  flex: 1 1;
  min-width: 120px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.7px;
}

.admin-users__users-header-expire {
  flex: 1 1;
  min-width: 120px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.7px;
}

.admin-users__users-header-btn {
  width: 50px;
  min-width: 50px;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.7px;
}

.admin-users__btn--wrap {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.admin-users__count-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  order: 1;
  min-width: 142px;
  margin-left: auto;
  margin-right: auto;
}

.admin-users__count {
  font-size: 20px;
  font-weight: 700;
  color: var(--primaryColor);
  line-height: 16px;
}

.admin-users__count-text {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  line-height: 16px;
  color: var(--customColor);
  white-space: nowrap;
}

.admin-users__price-wrap {
  min-width: 142px;
}

.admin-users__modal-trening-select {
  color: var(--customColor) !important;
}

.admin-users__modal-invitations-img {
  max-width: 215px;
  min-height: 215px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.admin-users__modal-invitations-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  color: var(--accentColor);
  text-align: center;
  margin-top: 15px;
  margin-bottom: 20px;
}

.admin-users__modal-invitations-text {
  max-width: 360px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--customColor);
  text-align: center;
}

.admin-users__empty-img-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: calc(100dvh - 311px);
  overflow: auto;
}

.admin-users__empty-img {
  width: 100%;
  height: auto;
  max-height: calc(100dvh - 450px);
  min-height: 200px;
  object-fit: contain;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 640px) {
  .admin-users {
    padding-bottom: 0;
  }
  .admin-users__main-wrap {
    padding: 35px 15px 30px 30px;
    border: 1px solid var(--accentColor2);
    border-radius: 10px;
  }
  .admin-users__users-wrap {
    max-height: calc(100vh - 337px);
    margin-top: 35px;
    gap: 0;
    padding-right: 20px;
  }
  .admin-users__users-wrap--is-active {
    max-height: calc(100vh - 276px);
  }
  .admin-users__users {
    gap: 0;
  }
  .admin-users__users-header-wrap {
    display: flex;
  }
  .admin-users__btn-wrap {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  .admin-users__count-wrap {
    align-items: flex-start;
    margin-left: 0;
  }
  .admin-users__count-wrap,
  .admin-users__price-wrap {
    order: 0;
    min-width: 160px;
    margin-right: 0;
  }
  .admin-users__count {
    font-size: 32px;
    line-height: 25px;
  }
  .admin-users__count-text {
    font-size: 14px;
    line-height: 25px;
  }
  .admin-users__btn--wrap {
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 25px;
    overflow: auto;
  }
  .admin-users__empty-img {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 720px) {
  .admin-users__count-wrap {
    align-items: flex-start;
  }
}
