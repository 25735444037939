.about-view-wrap {
  color: var(--primaryColor);
}

.about-view {
  position: relative;
  max-width: 1440px;
}

.about-view__info-wrap {
  margin-top: 100px;
  margin-bottom: 100px;
}

.about-view__project-wrap {
}

.about-view__project-title {
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
  color: var(--accentColor2);
  text-align: center;
}

.about-view__project-img-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-bottom: 40px;
}

.about-view__project-img-phone {
  max-width: 300px;
  box-shadow: 0px 0px 102.727px 0px rgba(10, 17, 53, 0.15);
  border-radius: 20.26px;
}

.about-view__project-img-desk {
  box-shadow: 0px 0px 102.727px 0px rgba(10, 17, 53, 0.15);
  border-radius: 15px;
}

.about-view__project-text-wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 100px;
}

.about-view__project-text {
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--customColor2);
}

.modal-window__modal-input-wrap {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.about-view__main-sub-title {
  white-space: nowrap;
}

@media (min-width: 420px) {
  .about-view__project-title {
    font-size: 65px;
    line-height: 65px;
  }
}
@media (min-width: 640px) {
  .about-view__project-title {
    font-size: 100px;
    line-height: 100px;
  }
  .about-view__info-wrap {
    margin-top: 200px;
    margin-bottom: 200px;
  }
  .about-view__project-text-wrap {
    margin-bottom: 200px;
  }
  .about-view__project-img-wrap {
    gap: 60px;
    margin-bottom: 60px;
    transform: translateY(-20px);
  }
}
@media (min-width: 960px) {
  .about-view__project-title {
    font-size: 158px;
    line-height: 158px;
  }
  .about-view__project-text-wrap {
    flex-direction: row;
  }
  .about-view__project-text {
    max-width: 48%;
  }
}
@media (min-width: 1200px) {
  .about-view__project-title {
    font-size: 200px;
    line-height: 200px;
  }
  .about-view__project-img-wrap {
    flex-direction: row;
    align-items: flex-start;
    transform: translateY(-30px);
  }
  .about-view__project-img-desk {
    width: 67%;
  }
  .about-view__project-text-wrap {
    gap: 70px;
  }
  .about-view__project-img-phone {
    max-width: none;
    width: 22.35%;
  }
}
@media (min-width: 1440px) {
  .about-view__project-title {
    font-size: 226px;
    line-height: 213px;
  }
}
