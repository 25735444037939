@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  position: relative;
  font-family: 'Oswald';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  overflow: hidden;
  padding: 0;
  /* touch-action: ''; */
  /* -ms-touch-action: ''; */
  /* -webkit-text-size-adjust: 100%; */
}

:root {
  /* --main-green: #2A7F77;
    --neutral-gray: #898989;
    --dark-charcoal: #19191D;
    --purplish-gray: #49454F; */

  --darkColor: rgba(37, 39, 38, 1);
  --primaryColor: rgba(10, 17, 53, 1);
  --customColor: rgba(94, 94, 94, 1);
  --customColor2: rgba(108, 112, 127, 0.5);
  --highlightColor: rgba(205, 255, 101, 1);
  --accentColor: rgba(143, 127, 243, 1);
  --accentColor2: rgba(192, 182, 255, 0.4);
  --borderColor: rgba(192, 182, 255, 0.4);
}

*,
::after,
::before {
  box-sizing: border-box;
}

/* *:last-child {
margin-bottom: 0;
} */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-family: 'Oswald', sans-serif;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
  color: inherit;
  font-family: 'Oswald', sans-serif;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  font-family: 'Oswald', sans-serif;
}

button,
label,
input {
  padding: 0;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  background-color: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: 'Oswald', sans-serif;
}

img,
svg {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: center;
}

button:focus {
  outline: none;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
  max-width: 960px;
}

.displayNone {
  display: none !important;
}

.disabledContainer {
  pointer-events: none;
  opacity: 0.6;
}

.hiddenContainer {
  display: block !important;
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
}

.opacityContainer-5 {
  opacity: 0.5;
}

.requiredFields {
  position: relative;
}

.requiredFields::after {
  content: '*';
  position: absolute;
  right: -8px;
  top: -8px;
  color: red;
}

.appInputFocus {
  /* padding-bottom: 250px !important; */
  max-height: 100% !important;
  height: 100% !important;
}

/* -------------Select------------ */

.MuiFormControl-root,
.MuiFormControl-root input,
.MuiFormLabel-root {
  font-family: 'Montserrat' !important;
  color: var(--customColor) !important;
}

.MuiOutlinedInput-notchedOutline {
  font-family: 'Montserrat' !important;
  border-color: var(--accentColor2) !important;
  border-width: 1px !important;
}

.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--accentColor) !important;
}

.MuiFormLabel-root.Mui-focused {
  color: var(--primaryColor) !important;
  border: none !important;
  outline: none !important;
}

.MuiDayCalendar-weekContainer .MuiButtonBase-root.Mui-selected {
  background-color: var(--accentColor) !important;
}

.MuiButtonBase-root .MuiSvgIcon-root {
  fill: var(--accentColor) !important;
}

.MuiTextField-root {
  width: 100%;
  outline: none !important;
}

.MuiPickersLayout-root .MuiButtonBase-root {
  color: var(--primaryColor);
}

.mu-component-is-active .MuiOutlinedInput-notchedOutline {
  border-color: var(--accentColor) !important;
}

.MuiSelect-iconOutlined {
  fill: var(--accentColor) !important;
}

/* ------------------------------- */
/* ---------------text editor---------------- */
.quill,
.quill * {
  font-family: 'Montserrat', sans-serif !important;
}

.ql-snow,
.ql-toolbar {
  border: 1px solid var(--accentColor2) !important;
}

.ql-toolbar {
  border-bottom: none !important;
}

.ql-editor p {
  font-family: 'Montserrat' !important;
}

/* ------------------------------- */
/* ---------------scroll---------------- */

*::-webkit-scrollbar {
  width: 0;
  height: 0;
}

*::-webkit-scrollbar-track {
  background: var(--accentColor2);
}

*::-webkit-scrollbar-thumb {
  background: var(--accentColor);
}

/* ------------time picker------------------- */
.MuiDialogActions-root {
  justify-content: center !important;
}

/* ------------------------------- */

.mainBtn--whidth {
  width: 100%;
}

.mainBtn--minWhidth {
  min-width: 140px;
}

.mainBtnDark {
  /* width: 100%; */
  min-width: 140px;
  font-size: 14px;
  font-weight: 500;
  padding: 9px 20px;
  color: #ffffff;
  background: var(--primaryColor);
  /* border: 1px solid #848484; */
  border: 1px solid var(--primaryColor);
  border-radius: 30px;
  text-align: center;
  transition: 0.3s;
}

.mainBtnWhite {
  /* width: 100%; */
  /* min-width: 140px; */
  min-width: 120px;
  font-size: 14px;
  font-weight: 500;
  padding: 9px 20px;
  color: var(--primaryColor);
  background: inherit;
  border: 1px solid var(--primaryColor);
  border-radius: 30px;
  text-align: center;
  transition: 0.3s;
}

.mainBtnWhite:hover {
  border: 1px solid var(--accentColor);
}

.mainBtnWhite:disabled {
  color: var(--customColor2);
  border: 1px solid #b5b7bf;
}

.mainBtnDark:hover {
  background: rgba(0, 16, 97, 1);
  border: 1px solid rgba(0, 16, 97, 1);
  /* border: 1px solid #b5b7bf; */
}

.mainBtnDark:disabled {
  background: var(--customColor2);
  border: 1px solid #b5b7bf;
}

#appleid-signin rect {
  stroke: #747775 !important
}

@media (min-width: 960px) {
  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  .mainBtnDark {
    min-width: 250px;
    font-size: 16px;
    padding: 12.5px 20px;
  }
  .mainBtnWhite {
    min-width: 250px;
    font-size: 16px;
    padding: 12.5px 20px;
  }
  .mainBtn--minWhidth {
    min-width: 140px;
  }
}
