.coaching-day-view-wrap {
  margin: 0 auto;
  width: 100%;
  max-width: 460px;
  padding: 15px 0;
  color: var(--primaryColor);
}

.coaching-day-view {
}

.coaching-day-view__day-wrap {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.coaching-day-view__info-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 15px;
}

.coaching-day-view__info-title-wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.coaching-day-view__info-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  color: var(--primaryColor);
}

.coaching-day-view__finish-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100dvh - 210px);
}

.coaching-day-view__finish-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: center;
  margin-bottom: 5px;
}

.coaching-day-view__finish-sub-title {
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--customColor);
  text-align: center;
  margin-bottom: 20px;
}

.coaching-day-view__btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 15px;
  margin-bottom: 15px;
}

.coaching-day-view__btn-loader {
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--accentColor);
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 5s infinite ease-out;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
