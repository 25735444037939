.license-view-wrap {
  color: var(--primaryColor);
}

.license-view {
  position: relative;
  max-width: 1440px;
}

.license-view__title--block {
  height: 90px;
  width: 100%;
  background: rgba(19, 18, 13, 1);
}

.license-view__title {
  /* font-size: 60px; */
  font-size: 31px;
  font-style: normal;
  font-weight: 600;
  line-height: 51px;
  color: var(--accentColor2);
  text-align: center;
  margin: 80px auto;
}

.license-view__text-wrap {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Montserrat' !important;
  font-size: 14px !important;
  margin-bottom: 80px;
}

.license-view__text-wrap .ql-editor {
  padding: 0 !important;
  font-family: 'Montserrat' !important;
  font-size: 14px !important;
  color: var(--customColor) !important;
}

.license-view__text-wrap .ql-tooltip {
  display: none !important;
}

@media (min-width: 640px) {
  .license-view__title {
    font-size: 42px;
    line-height: 42px;
  }
  .license-view__text-wrap {
    font-size: 16px !important;
  }
  .license-view__text-wrap .ql-editor {
    font-size: 16px !important;
  }
}
@media (min-width: 960px) {
  .license-view__title {
    font-size: 48px;
    line-height: 48px;
  }
}
@media (min-width: 1200px) {
  .license-view__title {
    font-size: 52px;
    line-height: 90px;
  }
}
@media (min-width: 1440px) {
}
