.footer-wrap {
  display: flex;
  color: var(--darkColor);
  /* min-height: 85px; */
  min-height: 72px;
  background: rgb(245, 255, 224);
}

.footer-wrap--notifications {
  min-height: 65px;
}

.footer {
  flex: 1 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.footer__btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  width: 100%;
  max-width: 460px;
}

.footer__btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 49%;
  padding: 6.5px;
  border-radius: 60px;
  transition: 0.3s;
}

.footer__btn-text-wrap--active {
  font-weight: 500;
  scale: 1.18;
}
