.instruction-view-wrap {
  margin: 0 auto;
  width: 100%;
  max-width: 460px;
  padding: 15px 0;
  color: var(--primaryColor);
}

.instruction-view {
  display: flex;
  flex-direction: column;
  height: calc(100dvh - 158px);
  overflow: auto;
}

.instruction-view__sub-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  margin-bottom: 15px;
  color: var(--primaryColor);
}

.instruction-view__equipment-wrap {
  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 12px;
  border: 1px solid var(--accentColor2);
  margin-bottom: 30px;
}

.instruction-view__equipment-img-wrap {
  border-right: 1px solid var(--accentColor2);
  border-radius: 12px 0 0 12px;
  background: #fff;
}

.instruction-view__equipment-img {
  width: 90px;
  height: 90px;
}

.instruction-view__equipment-name {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
}

.instruction-view__text-wrap {
  border: 1px solid var(--accentColor2);
  border-radius: 12px;
  margin-bottom: 25px;
  font-family: 'Montserrat';
}

.instruction-view__text-wrap .ql-editor {
  /* white-space: pre !important; */
}

.instruction-view__text-wrap p {
  font-family: 'Montserrat';
}

.instruction-view__text-wrap .ql-hidden {
  display: none !important;
}

.instruction-view__equipment-img--no-image {
  padding: 10px;
}

.instruction-view__modal-img {
  width: 100%;
}

.instruction-view__modal-name {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  padding: 5px 0;
  border: 1px solid var(--accentColor2);
  border-radius: 12px;
  margin-top: 20px;
}

.instruction-view__error-text {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  color: var(--customColor);
  text-align: center;
  padding: 30px 0px;
}
