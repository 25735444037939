.restore-password-wrap {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  height: 100vh;
  overflow: auto;
  padding: 20px 0;
}

.restore-password__modal-img {
  width: 80px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.restore-password__modal-title {
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--primaryColor);
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.restore-password__modal-btn-wrap {
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
}

.restore-password__modal-btn {
  min-width: 150px;
}

.restore-password__desktop-img-wrap {
  display: none;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  background: rgba(242, 242, 242, 1);
}

.restore-password__desktop-img-logo {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 78px;
  height: auto;
  margin-bottom: 30px;
}

.restore-password__desktop-img {
  width: 100%;
  height: 100vh;
  object-position: top;
}

.restore-password {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  font-size: var(--text-size);
  min-height: 0;
}

.restore-password__form-wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.restore-password__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 460px;
}

.restore-password__form-input-wrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 460px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}

.restore-password__form-input {
  width: 100%;
}

.restore-password__dialogform {
}

.restore-password__dialogform .MuiPaper-root {
  width: 90%;
  max-width: 460px;
  margin: 20px;
  background: #f2f2f2;
  border-radius: 27px;
}

.restore-password__dialogform-btn-wrap {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 5px 24px 0 24px;
}

.restore-password__form-btn-wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
}

.restore-password__form-btn--wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
}

.restore-password__form-title-wrap {
  display: none;
  align-items: center;
  width: 100%;
}

.restore-password__form-title {
  font-size: 22px;
  font-weight: 500;
  margin-right: auto;
  margin-bottom: 13px;
}

.restore-password__desktop-form-sub-title {
  align-self: flex-start;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--customColor);
  margin-bottom: 34px;
}

@media (min-width: 960px) {
  .restore-password-wrap {
    flex-direction: row;
    padding: 0;
  }
  .restore-password__form {
    max-width: 460px;
  }
  .restore-password__form-input-wrap {
    max-width: 460px;
  }
  .restore-password__desktop-img-wrap {
    display: flex;
  }
  .restore-password__form-title-wrap {
    display: flex;
    flex-direction: column;
  }
  .restore-password__form-wrap {
    justify-content: center;
  }
  .restore-password__form-btn-back {
    display: none;
  }
}
