.coaching-day-card {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--accentColor2);
  border-radius: 12px;
  transition: 0.3s;
}

.coaching-day-card:hover {
  border: 1px solid var(--accentColor);
}

.coaching-day-card:hover .coaching-day-card__img-wrap {
  border-right: 1px solid var(--accentColor);
}

.coaching-day-card__title--wrap {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-right: 15px;
}

.coaching-day-card__title-wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 15px;
}

.coaching-day-card__title {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  color: var(--primaryColor);
}

.coaching-day-card__sub-title {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: lowercase;
  color: var(--customColor);
}

.coaching-day-card__img-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  min-width: 80px;
  height: 80px;
  border-right: 1px solid var(--accentColor2);
}

.coaching-day-card__img {
  width: 30px;
  height: auto;
}

.coaching-day-card__img--finnish {
  width: 25px;
  height: auto;
}

.coaching-day-card__btn {
  height: 32px;
  padding: 3px 20px;
  border-radius: 30px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  color: var(--primaryColor);
  background: var(--highlightColor);
  transition: 0.3s;
}

.coaching-day-card__btn:hover {
  background: var(--accentColor);
  color: #fff;
}
