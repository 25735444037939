.modal-notifications {
  width: 100%;
  min-width: 300px;
  max-width: 300px;
  padding: 13px 14px;
  background: #ffffff;
  color: var(--primaryColor);
}

.modal-notifications__title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  color: var(--primaryColor);
  margin-bottom: 20px;
}

.modal-notifications__content-item-text-name {
  color: #1d1b20;
  margin-right: 5px;
}

.modal-notifications__btn-wrap {
  display: flex;
  align-items: center;
  gap: 40px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--accentColor2);
}

.modal-notifications__btn {
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.modal-notifications__btn--active {
  text-decoration-line: underline;
  background: var(--highlightColor);
  color: var(--primaryColor);
}

.modal-notifications__content {
  height: calc(100dvh - 200px);
  max-height: 280px;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: auto;
  padding-right: 3px;
}

.modal-notifications__content::-webkit-scrollbar {
  width: 3px;
  background-color: rgb(217, 214, 214);
}

.modal-notifications__content-day-wrap {
  margin-bottom: 20px;
}

.modal-notifications__content-day {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.7px;
  color: var(--primaryColor);
  margin-bottom: 15px;
}

.modal-notifications__content-items {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.modal-notifications__content-item {
  display: flex;
  gap: 10px;
}

.modal-notifications__content-item-img-wrap {
  position: relative;
}
.modal-notifications__content-item-img {
  width: 50px;
  height: 50px;
  border-radius: 8px;
}

.modal-notifications__content-item-img-text {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Oswald';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #fff;
}

.modal-notifications__content-item-text-wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.modal-notifications__content-item-text {
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #5e5e5e;
}

.modal-notifications__content-item-date {
  font-family: 'Montserrat';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  color: #747474;
}

.modal-notifications__content-item-btn {
  font-size: 10px;
  font-weight: 500;
  color: #fff;
  background: rgba(161, 161, 161, 1);
  border: 1px solid rgba(161, 161, 161, 1);
  border-radius: 5px;
  padding: 3px 8px;
}

.modal-notifications__content-item-btn--cancel {
  color: rgba(161, 161, 161, 1);
  background: #fff;
}

.modal-notifications__btn-all-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-notifications__btn-all {
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--accentColor);
  text-decoration: underline;
}

.modal-notifications__content-item-btn-add {
  width: fit-content;
  min-width: 90px;
  max-height: 30px;
  padding: 4px 15px;
}

.modal-notifications__empty-wrap {
  font-size: 16px;
  font-weight: 400;
  color: var(--customColor);
  text-align: center;
  padding: 30px 0px;
}

@media (min-width: 640px) {
  .modal-notifications__content {
    max-height: 340px;
  }
}
