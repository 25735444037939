.result-image__wrap {
  position: relative;
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  min-height: 90px;
  background: var(--accentColor2);
  border-radius: 5px;
  margin-bottom: 15px;
}

.result-image__img-wrap {
  position: relative;
  cursor: pointer;
}

.result-image__img-update {
  position: absolute;
  top: 50%;
  right: -16px;
  border-radius: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 32px;
  height: auto;
  background: #fff;
}

.result-image__main {
  width: auto;
  height: 90px;
  aspect-ratio: 1.8 / 1;
  border-radius: 5px 0 0 5px;
}

.result-image__equipment {
  width: 90px;
  height: 90px;
}

.result-image__preview {
  height: 90px;
  aspect-ratio: 1.27 / 1;
  border-radius: 5px 0 0 5px;
}

.result-image__name-wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  height: 75px;
}

.result-image__name {
  max-width: calc(100vw - 222px);
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--primaryColor);
  margin-top: 5px;
  overflow: auto;
}

.result-image__delete {
  cursor: pointer;
  width: 30px;
  height: auto;
  margin-left: auto;
  margin-right: 20px;
}
