.text-input {
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.MuiFormHelperText-root {
  color: red !important;
  font-family: 'Montserrat' !important;
}
