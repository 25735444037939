.scanner-camera {
  width: calc(100vw - 2px);
  max-width: 460px;
  min-height: 300px;
  transform: translateX(-15px);
}

.scanner-camera video {
  width: 100% !important;
}
