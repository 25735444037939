.modal-window {
  position: fixed;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  transition: 0.3s;
}

.modal-window-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  transition: 0.3s;
}

.modal-window--wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 420px;
  background: #ffffff;
  border-radius: 27px;
  border: 1px solid var(--accentColor2);
  box-shadow:
    rgb(0 0 0 / 20%) 0px 11px 15px -7px,
    rgb(0 0 0 / 14%) 0px 24px 38px 3px,
    rgb(0 0 0 / 12%) 0px 9px 46px 8px;
  padding: 16px 24px;
  z-index: 16;
  color: #000000;
  max-height: 100vh;
  transition: 0.3s;
}

.modal-window__title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;
}

.modal-window__text {
  font-size: 16px;
  margin-bottom: 15px;
}

.modal-window__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  aspect-ratio: 9/12;
}

.modal-window__btn-wrap {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  margin-top: auto;
  margin-top: 30px;
}

.modal-window__btn-wrap .MuiButtonBase-root {
  min-width: 110px !important;
}

.modal-window__close-btn {
  position: absolute;
  top: -22px;
  right: -22px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.modal-window__close-btn-img {
  width: 20px;
  height: 20px;
}

.modal-window__btn {
  position: relative;
  min-width: 135px;
  min-height: 40.4px;
}

.modal-window__btn-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  display: block;
  width: 30px;
  height: 30px;
  padding: 6px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #ffffff;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

@media (min-width: 576px) {
  .modal-window {
    font-size: 14px;
  }
  .modal-window__title {
    font-size: 20px;
  }
  .modal-window--wrap {
    padding: 30px 20px;
  }
}
@media (min-width: 768px) {
  .modal-window--wrap {
    width: 100%;
  }
  .modal-window__btn-wrap {
    gap: 25px;
  }
}
@media (min-width: 960px) {
  .modal-window--wrap {
    max-width: 580px;
    width: auto;
  }
  .modal-window__btn {
    min-width: 150px;
    min-height: 50.6px;
  }
}
@media (min-width: 1200px) {
  .modal-window {
    font-size: 16px;
  }
}
