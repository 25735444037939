.user-view-wrap {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 460px;
  min-height: 0;
  padding: 15px 0;
}

.user-view {
  position: relative;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.user-view__trending-wrap {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.user-view__not-cards-title {
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.user-view__not-cards {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user-view__not-cards-img-man {
  width: auto;
  max-width: 160px;
  height: auto;
  max-height: 292px;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
}

.user-view__not-cards-img-circle {
  width: 100%;
  height: auto;
  transform: translateY(-12px);
}
