.admin-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100vw;
  margin-left: -15px;
  padding: 13px 15px;
  /* border-radius: 16px; */
  background: rgb(245 255 224);
}

.admin-header__btn-back-wrap {
  display: flex;
  align-items: center;
  gap: 20px;
}

.admin-header__btn-back {
  display: flex;
  align-items: center;
  gap: 20px;
}

.admin-header__btn-back-img {
  width: 30px;
  height: auto;
}

.admin-header__btn-back-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--primaryColor);
}

.admin-header__search-wrap {
  flex: 1 1;
  position: relative;
  width: 100%;
  max-width: 460px;
}

.admin-header__search {
  width: 100%;
  height: 40px;
  font-family: 'Montserrat', sans-serif;
  padding: 10px 40px 12px 20px;
  border: 1px solid var(--accentColor2);
  border-radius: 10px;
  transition: 0.3s;
}

.admin-header__search::placeholder {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--customColor2);
}

.admin-header__search--active,
.admin-header__search:focus,
.admin-header__search:hover {
  border: 1px solid var(--accentColor);
}

.admin-header__search-img {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.admin-header__btn-wrap {
  display: flex;
  align-items: center;
  gap: 20px;
}

.admin-header__btn-search {
  position: relative;
  width: 35px;
  height: 28px;
}

.admin-header__search-modal--wrap {
}

.admin-header__search-modal--wrap .MuiBackdrop-root {
  background: #00000045 !important;
}

.admin-header__search-modal--wrap .MuiPaper-root {
  border-radius: 8px !important;
  left: 17px !important;
  top: 60px !important;
}

.admin-header__search-modal-wrap {
  position: relative;
  width: calc(100vw - 32px);
  border: 3px solid var(--accentColor2);
  border-radius: 8px;
}

.admin-header__search-modal-input {
  width: 100%;
  height: 56px;
  border-radius: 8px;
  padding: 0 40px 0 20px;
  font-family: 'Montserrat' !important;
}

.admin-header__search-modal-btn-img {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.admin-header__search-modal-btn-delete-img {
  width: 16px;
  height: 16px;
}

.admin-header__btn {
  position: relative;
  display: block;
  width: 28px;
  height: auto;
}

.admin-header__btn-new-notifications {
  position: absolute;
  top: 3px;
  right: 5px;
  width: 6px;
  height: 6px;
  background: var(--accentColor);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-header__user-img-wrap {
  display: none;
  position: relative;
}

.admin-header__user-img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

.admin-header__user-img--active {
  border: 2px solid var(--accentColor);
}

.admin-header__user-img-name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Oswald';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #fff;
}

.admin-header__search-wrap--disabled {
  pointer-events: none;
  opacity: 0;
  width: 0;
}

.admin-header__notifications-modal--wrap {
}

.header__notifications-modal--wrap .MuiPaper-root,
.admin-header__notifications-modal--wrap .MuiPaper-root {
  box-shadow: none;
  border: 1px solid var(--accentColor2);
  border-radius: 20px;
  margin-top: 19px;
  margin-left: 2px;
}

.header__notifications-modal--wrap .MuiPaper-root {
  margin-left: 1.5px;
}

@media (min-width: 640px) {
  .admin-header {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
    border: 1px solid var(--accentColor2);
    border-radius: 10px;
    padding: 9px 20px;
    background: inherit;
  }
  .admin-header__user-img-wrap {
    display: block;
  }
  .admin-header__btn-search {
    display: none;
  }
  .admin-header__btn-back-text {
    font-size: 20px;
    color: var(--primaryColor);
  }
  .admin-header__notifications-modal--wrap .MuiPaper-root {
    margin-left: -3px;
  }
}
