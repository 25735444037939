.select-exercise--wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  padding-top: 5px;
}

.select-exercise-wrap {
  max-width: calc(100vw - 130px);
}

.select-exercise-wrap--full {
  max-width: none;
}

.select-exercise__label {
}

.select-exercise__select {
}

.select-exercise__select .MuiPaper-root {
  max-height: 250px;
}

.select-exercise__select .MuiPaper-root {
  box-shadow: none;
  border: 1px solid var(--accentColor);
}

.select-exercise__exercise-add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  min-width: 56px;
  height: 56px;
  font-size: 64px;
  font-weight: 300;
  padding: 10px;
  border: 1px solid var(--accentColor);
  border-radius: 4px;
  color: var(--accentColor);
}

.select-exercise__exercise-add-btn-span {
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -4px;
}

.select-exercise__move-img {
  width: 24px;
  height: auto;
}

@media (min-width: 640px) {
  .select-exercise--wrap {
    gap: 20px;
  }
  .select-exercise-wrap {
    max-width: none;
  }
}
