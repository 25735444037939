.scanning-view-wrap {
  margin: 0 auto;
  width: 100%;
  max-width: 460px;
  padding: 15px 0;
}

.scanning-view {
}

.scanning-view__title {
  display: block;
  width: 136px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
  color: var(--primaryColor);
  margin-top: 14px;
}
