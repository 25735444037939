.show-message__success {
  min-width: 250px !important;
  max-width: 100vw !important;
  color: var(--customColor) !important;
  background: #fff !important;
  border: 1px solid var(--accentColor);
}

.show-message__success .MuiSvgIcon-root {
  fill: var(--accentColor) !important;
}

.show-message__error {
  min-width: 250px !important;
  max-width: 100vw !important;
  background: #fff !important;
  border: 1px solid rgb(223, 17, 17) !important;
  color: rgb(223, 17, 17) !important;
}

.show-message__error .MuiSvgIcon-root {
  fill: rgb(223, 17, 17) !important;
}

.show-message__hidden {
  opacity: 0;
}
