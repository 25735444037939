.admin-training-day-wrap {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.admin-training-day {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.admin-training-day__input-wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: auto;
  max-height: calc(100dvh - 201px);
}

.admin-training-day__input--wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding-top: 15px;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
}

.admin-training-day__input-name-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.admin-training-day__btn-duplicate {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-width: 56px;
  height: 56px;
  padding: 10px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  border: 1px solid var(--accentColor);
  border-radius: 4px;
  color: var(--accentColor);
}

.admin-training-day__btn-duplicate-text {
  display: none;
  white-space: nowrap;
}

.admin-training-day__btn-duplicate-img {
  width: 25px;
  height: auto;
}

.admin-training-day__exercise-input--wrap {
  padding-top: 5px;
  background: #fff;
}

.admin-training-day__exercise-input--wrap:not(:last-child) {
  margin-bottom: 20px;
}

.admin-training-day__exercise-input-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.admin-training-day__exercise-btn-delete {
  align-self: flex-end;
  width: 56px;
  min-width: 56px;
  height: 56px;
  padding: 10px;
  border: 1px solid var(--accentColor);
  border-radius: 4px;
}

.admin-training-day__add-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.admin-training-day__popover-btn {
  align-self: flex-end;
}

.admin-training-day__popover-btn-img {
  width: 56px;
  height: auto;
}

.admin-training-day__popover-wrap {
}

.admin-training-day__popover-wrap .MuiPaper-root {
  box-shadow: none;
}

.admin-training-day__popover-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 10px;
}

@media (min-width: 640px) {
  .admin-training-day__input-wrap {
    max-height: calc(100vh - 189px);
  }
  .admin-training-day__btn-duplicate {
    border-radius: 10px;
    min-width: fit-content;
  }
  .admin-training-day__btn-duplicate-text {
    display: block;
  }
  .admin-training-day__exercise-input-wrap {
    gap: 20px;
  }
  .admin-training-day__input--wrap {
    padding-right: 20px;
  }
  .admin-training-day__add-btn-wrap {
    margin-top: 30px;
  }
}
