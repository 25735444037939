.sign-in-wrap {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  height: 100vh;
  overflow: auto;
  padding: 20px 0 65px;
}

.sign-in {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  min-height: 0;
  font-size: var(--text-size);
}

.sign-in__form-wrap {
  position: relative;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 100vh;
}

.sign-in__form-btn-back {
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
}

.sign-in__form-btn-back-img {
  width: 27px;
  height: auto;
}

.sign-in__form-btn-back--app {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.sign-in__form-title--app {
  transform: translateX(22px);
}

.sign-in__form-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
}

.sign-in__form {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 460px;
  padding-right: 5px;
}

.sign-in__form-google-btn-wrap {
  position: relative;
  display: block;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.sign-in__form-google-btn-img {
  width: 100%;
}

.sign-in__form-google-btn {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 1 !important;
  opacity: 0 !important;
}

.sign-in__form-input-wrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 460px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}

.sign-in__form-input {
  width: 100%;
}

.sign-in__form-btn-forget {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  color: var(--customColor2);
  text-transform: capitalize;
}

.sign-in__dialogform {
}

.sign-in__dialogform .MuiPaper-root {
  width: 90%;
  max-width: 460px;
  margin: 20px;
  background: #f2f2f2;
  border-radius: 27px;
}

.sign-in__dialogform-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 5px 24px 0 24px;
}

.sign-in__dialogform-btn-wrap .MuiButtonBase-root {
  min-width: 110px !important;
}

.sign-in__form-btn-wrap {
  width: 100%;
}

.sign-in__form-btn-create-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
  width: 100%;
}

.sign-in__form-btn-create {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  color: var(--primaryColor);
}

.sign-in__desktop {
  flex: 1 1;
  display: flex;
  height: 100vh;
}

.sign-in__desktop-img-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  background: rgba(242, 242, 242, 1);
}

.sign-in__desktop-img-logo {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 78px;
  height: auto;
  margin-bottom: 30px;
}

.sign-in__desktop-img {
  width: 100%;
  height: 100vh;
  object-position: top;
}

.sign-in__desktop-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  max-width: 460px;
}

.sign-in__desktop-form-title {
  align-self: flex-start;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  color: var(--primaryColor);
  margin-bottom: 13px;
}

.sign-in__desktop-form-sub-title {
  align-self: flex-start;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--customColor);
  margin-bottom: 34px;
}

.sign-in__desktop-form-input-wrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 460px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
}

.sign-in__desktop-form-btn-forget {
  display: block;
  margin-left: auto;
  margin-bottom: 30px;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--customColor2);
  text-transform: capitalize;
}

.sign-in__desktop-form-or {
  width: 100%;
  border-bottom: 1px solid var(--accentColor2);
  margin-top: 20px;
  margin-bottom: 40px;
}

.sign-in__desktop-form-or-text {
  transform: translateY(46%);
  width: fit-content;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 500;
  color: var(--customColor);
  padding: 10px 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background: #fff;
  padding: 11px;
}

.sign-in__desktop-form-btn--wrap {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 5px;
  width: 100%;
  margin-top: 20px;
}

.sign-in__desktop-form-btn-text {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--customColor);
}

.sign-in__desktop-form-btn {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--customColor);
}

.sign-in__input-form--wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.sign-in__form-title-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  margin-bottom: 30px;
  padding: 15px;
}

@media (min-width: 960px) {
  .sign-in-wrap {
    padding: 0;
  }
  .sign-in__form-wrap {
    overflow: auto;
    padding: 60px 0;
  }
}
