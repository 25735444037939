.info-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--accentColor2);
  border-radius: 20px;
  color: var(--primaryColor);
}

.info-block__img-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  min-height: 300px;
  border-radius: 13px 13px 0 0;
  border-bottom: 1px solid var(--accentColor2);
}

.info-block__img {
  width: 100%;
  border-radius: 13px 13px 0 0;
}

.info-block__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 30px;
}

.info-block__main-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.info-block__main-number {
  font-size: 69.488px;
  font-style: normal;
  font-weight: 600;
  line-height: 63px;
  color: var(--accentColor);
  margin-bottom: 40px;
}

.info-block__main-text {
  flex: 1 1;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--customColor);
  text-align: center;
  margin-bottom: 30px;
}

.info-block__main-btn {
  width: 100%;
  max-width: 240px;
  height: 55px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  color: var(--primaryColor);
  background: var(--highlightColor);
  border-radius: 60px;
  padding: 5px 40px;
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;
}

.info-block__main-number--left {
  margin-left: -5px;
}

.info-block__img--about {
  width: 130px !important;
  height: auto;
}

.info-block__main-number--about {
}

@media (min-width: 768px) {
  .info-block {
    flex-direction: row;
    border: 5px solid var(--accentColor2);
  }
  .info-block__img-wrap {
    width: 50%;
    border-radius: 13px 0 0 13px;
    border-bottom: none;
    border-right: 5px solid var(--accentColor2);
  }
  .info-block__img {
    border-radius: 13px 0 0 13px;
    border-bottom: none;
  }
  .info-block__main {
    align-items: flex-start;
    width: 50%;
    padding: 50px 30px 50px;
  }
  .info-block__main-number {
    font-size: 100px;
    line-height: 111px;
  }
  .info-block__main-number--about {
    font-size: 70px;
    line-height: 63px;
  }
  .info-block__main-text {
    font-size: 20px;
    text-align: start;
  }
  .info-block__main-btn {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 960px) {
  .info-block__main {
    padding: 80px 30px 50px;
  }
  .info-block__main-number {
    font-size: 125.488px;
  }
  .info-block__main-number--about {
    font-size: 96px;
    line-height: 86px;
  }
}
@media (min-width: 1200px) {
  .info-block__main {
    padding: 120px 100px 50px;
  }
  .info-block__main-number {
    font-size: 158.488px;
    line-height: 173px;
  }
  .info-block__main-number--about {
    font-size: 96px;
    line-height: 86px;
  }
}
@media (min-width: 1440px) {
  .info-block__main-number {
    font-size: 190.488px;
    line-height: 173px;
  }
  .info-block__main-number--about {
    font-size: 96px;
    line-height: 86px;
  }
}
