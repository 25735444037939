.header-wrap {
  background: rgb(245, 255, 224);
  color: #1d1b20;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 12px 15px;
}

.header__name-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header__form-btn-back-wrap {
  padding: 6px 0;
}

.header__form-btn-back {
  width: 16px;
  height: auto;
}

.header__name {
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  color: var(--primaryColor);
}

.header__name-span {
  font-family: 'Montserrat';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 7px;
  color: var(--primaryColor);
  margin-top: 3px;
}

.header__btn-wrap {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header__login-btn {
  position: relative;
  display: block;
  width: 25px;
  height: auto;
}

.header__login-btn--scanning {
  background: var(--highlightColor);
  border-radius: 5px;
}

.header__notification-new {
  position: absolute;
  top: 3px;
  right: 5px;
  width: 6px;
  height: 6px;
  background: var(--accentColor);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__qrcode {
}

.header__qrcode-path {
  fill: var(--primaryColor);
}
