.create-exercise {
  display: flex;
  flex-direction: column;
}

.create-exercise__input-wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.create-exercise__create-img-link-upload-input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  top: 0;
}

.create-exercise__create-img-result-wrap {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  min-height: 90px;
  background: var(--accentColor2);
  border-radius: 5px;
  margin-bottom: 15px;
}

.create-exercise__create-img-result-delete {
  cursor: pointer;
  width: 30px;
  height: auto;
  margin-left: auto;
  margin-right: 20px;
}

.create-exercise__create-video-result-wrap {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
  cursor: pointer;
}

.create-exercise__create-video-result {
  width: 30px;
  height: auto;
  margin-left: 20px;
  cursor: pointer;
}

.create-exercise__create-img-result-name-wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  height: 75px;
}

.create-exercise__create-img-result-video {
  cursor: pointer;
}

.create-exercise__create-img-result-name {
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--primaryColor);
  margin-top: 5px;
  overflow: auto;
}

.create-exercise__create-img-result-watch {
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--customColor);
  text-decoration: underline;
}

.create-exercise__create-img-result-size {
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--customColor);
}

.create-exercise__instruction-wrap {
  margin-top: 20px;
  margin-bottom: 20px;
  /* max-width: 430px; */
}

.create-exercise__instruction-title {
  font-size: 16px;
  font-family: 'Montserrat';
  color: var(--customColor);
  margin-bottom: 5px;
}

.create-exercise__instruction {
}

.create-exercise__instruction .ql-container {
  height: 130px;
}

.create-exercise__modal-video {
  width: 100%;
  max-width: 100%;
  background: #000;
}

.create-exercise__create-img-cropper-wrap {
  flex: 1 1;
  position: relative;
  width: 100%;
}

@media (min-width: 640px) {
  .create-exercise__create-img-result-name {
    font-size: 16px;
  }
}
