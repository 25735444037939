.notifications-view-wrap {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
  min-height: 0;
}

.notifications-view {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 15px;
  border-radius: 10px;
}

.notifications-view__btn--wrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
}

.notifications-view__btn-wrap {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.notifications-view__count-wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.notifications-view__count {
  font-size: 20px;
  font-weight: 700;
  color: var(--primaryColor);
  line-height: 16px;
  text-align: center;
}

.notifications-view__count-text {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  line-height: 16px;
  color: var(--customColor);
  white-space: nowrap;
}

.notifications-view__content-items-wrap {
  flex: 1 1;
  overflow: hidden;
}

.notifications-view__content-items {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: calc(100dvh - 247px);
  margin-top: 20px;
  padding-bottom: 20px;
  overflow: auto;
}

.notifications-view__content-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 100px;
  border: 1px solid var(--accentColor2);
  border-radius: 10px;
  padding-bottom: 15px;
  padding-right: 5px;
  padding: 15px;
  font-family: 'Montserrat', sans-serif;
}

.notifications-view__mobile-card-title--wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 15px;
}

.notifications-view__mobile-card-title-wrap {
}

.notifications-view__mobile-card-title {
  font-size: 14px;
  margin-right: 5px;
  color: rgba(29, 27, 32, 1);
}

.notifications-view__mobile-card-days {
  font-size: 12px;
  color: rgba(128, 128, 128, 1);
  margin-top: 10px;
}

.notifications-view__mobile-card-title-wrap--archived {
  opacity: 0.6;
}

.notifications-view__content-item-text-wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.notifications-view__content-item-text {
  flex: 1 1;
  font-size: 15px;
  font-weight: 500;
  color: rgba(116, 116, 116, 1);
}

.notifications-view__content-item-date {
  font-size: 12px;
  font-weight: 500;
  color: rgba(116, 116, 116, 1);
}

@media (min-width: 640px) {
  .notifications-view__content-items {
    padding-right: 5px;
  }
}
