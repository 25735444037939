.business-view__main-sub-title {
  max-width: 650px;
  font-family: 'Montserrat';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #fff;
  text-align: center;
}

@media (min-width: 640px) {
}

@media (min-width: 768px) {
  .business-view__main-sub-title {
    font-size: 24px;
  }
}

@media (min-width: 960px) {
}
