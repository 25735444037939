.double-buttons {
  display: flex;
  width: 100%;
}

.double-buttons__btn-left {
  width: 50%;
  font-size: 14px;
  font-weight: 500;
  padding: 6.5px 20px;
  color: var(--customColor);
  border: 1px solid var(--primaryColor);
  border-radius: 10px 0 0 10px;
  white-space: nowrap;
  text-transform: capitalize;
}

.double-buttons__btn-right {
  width: 50%;
  font-size: 14px;
  font-weight: 500;
  padding: 6.5px 20px;
  color: var(--customColor);
  border: 1px solid var(--primaryColor);
  border-radius: 0 10px 10px 0px;
  white-space: nowrap;
  text-transform: capitalize;
}

.double-buttons__btn-left:hover,
.double-buttons__btn-right:hover {
  border: 1px solid var(--accentColor);
}

.double-buttons__btn--active {
  background: var(--primaryColor);
  color: rgba(255, 255, 255, 1);
}

.double-buttons__btn--active:hover {
  border: 1px solid var(--primaryColor) !important;
}

@media (min-width: 640px) {
  .double-buttons__btn-left {
    width: fit-content;
  }
  .double-buttons__btn-right {
    width: fit-content;
  }
  .double-buttons__btn-left,
  .double-buttons__btn-right {
    font-size: 16px;
  }
}
