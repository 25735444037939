.admin-create-plan-wrap {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.admin-create-plan {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.admin-create-plan__input-wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: auto;
  max-height: calc(100dvh - 214px);
  padding-top: 15px;
}

.admin-create-plan__input--wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 5px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.admin-create-plan__btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-create-plan__add-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
  flex-wrap: wrap;
}

@media (min-width: 640px) {
  .admin-create-plan {
    padding-bottom: 0;
  }
  .admin-create-plan__input-wrap {
    max-height: calc(100vh - 203px);
    padding-top: 0;
    padding-right: 20px;
  }
  .admin-create-plan__btn-wrap {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}
