.billing-view-wrap {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
  padding-top: 16px;
}

.billing-view {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.billing-view__mob-btn-stripe {
  margin-top: 15px;
}

.billing-view__btn-download {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 40px;
  min-height: 40px;
  border: 1px solid var(--primaryColor);
  border-radius: 30px;
  margin-top: 15px;
  margin-bottom: 30px;
}

.billing-view__btn-download-img {
  width: 25px;
  height: auto;
}

.billing-view__transactions-wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100vh - 319px);
  padding-bottom: 20px;
  overflow: auto;
}

.billing-view__transactions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid var(--accentColor2);
  border-radius: 11px;
  padding: 20px 15px;
}

.billing-view__transactions-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.billing-view__transactions-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  color: var(--primaryColor);
}

.billing-view__transactions-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.billing-view__transactions-text {
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--customColor);
}

.billing-view__no-transactions {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
  color: var(--primaryColor);
  text-align: center;
}

.billing-view__modal-add-wrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.billing-view__modal-add-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
  color: var(--primaryColor);
}

.billing-view__download-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: fit-content;
}

.billing-view__transaction-btn-download-img {
  width: 20px;
  height: auto;
}

.billing-view__add-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 100%;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.billing-view__transaction-item-cards--wrap {
  flex: 1 1;
  overflow: hidden;
}

.billing-view__transaction-item-cards-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0;
  max-height: calc(100vh - 349px);
  padding-right: 20px;
  margin-top: 20px;
  overflow: auto;
}

.billing-view__transaction-item-cards {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.billing-view__transaction-item-cards--gap {
  gap: 20px;
}

.billing-view__transaction-header-wrap {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  min-width: 570px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.7px;
  color: var(--customColor);
  background: #ffffff;
  white-space: nowrap;
}

.billing-view__transaction-header-date {
  width: 125px;
  padding-left: 40px;
}

.billing-view__transaction-header-period {
  width: 180px;
}

.billing-view__transaction-header-amount {
  width: 70px;
}

.billing-view__transaction-header-number {
  width: 105px;
}

.billing-view__transaction-item-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  min-width: 570px;
  padding: 35px 0;
  border-bottom: 1px solid var(--accentColor2);
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--customColor);
}

.billing-view__transaction-item-card-date-wrap {
  display: flex;
  align-items: center;
  gap: 30px;
}

.billing-view__transaction-item-card-index {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  color: var(--customColor);
}

.billing-view__transaction-item-card-date {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  white-space: nowrap;
  color: var(--primaryColor);
}

.billing-view__transaction-item-card-date-wrap {
  width: 125px;
}

.billing-view__transaction-item-card-period {
  width: 180px;
}

.billing-view__transaction-item-card-amount {
  width: 70px;
}

.billing-view__dialogform-text {
  max-width: 340px;
}

.billing-view__transactions-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-width: 105px;
  max-height: 30px;
  padding: 4px 15px;
}

.billing-view__empty-img-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 100%;
  max-height: calc(100vh - 166px);
  overflow: auto;
}

.billing-view__empty-img {
  width: 100%;
  height: auto;
  height: calc(100vh - 400px);
  min-height: 200px;
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
}

@media (min-width: 640px) {
  .billing-view__empty-img-wrap {
    gap: 0;
    max-height: calc(100vh - 317px);
  }
}
