.user-card__mobile {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  min-height: 135px;
  border: 1px solid var(--accentColor2);
  border-radius: 12px;
  padding: 15px;
  color: var(--primaryColor);
  cursor: pointer;
}

.user-card__mobile-text {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.user-card__mobile-text-name {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  color: var(--primaryColor);
}

.user-card__mobile-text-phone {
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--customColor2);
}

.user-card__mobile-text-email-wrap {
}

.user-card__mobile-text-email {
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
  color: var(--customColor2);
}

.user-card__mobile-img-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px;
}

.user-card__mobile-img {
  width: 40px;
  height: 40px;
}

.user-card__mobile-btn {
  width: 100%;
  max-width: 105px;
  height: 32px;
  background: var(--primaryColor);
  color: rgba(255, 255, 255, 1);
  border-radius: 91px;
  padding: 3px 15px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
}

.user-card__mobile-text-days-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
}

.user-card__mobile-text-days {
  display: flex;
  flex-direction: column;
}

.user-card__mobile-text-days-text {
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--customColor);
}

.user-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  min-width: 830px;
  border-bottom: 1px solid var(--accentColor2);
  padding: 15px 0;
}

.user-card--archive {
  color: rgba(61, 61, 61, 0.5);
}

.user-card__num {
  min-width: 17px;
  font-size: 18px;
  font-weight: 500;
}

.user-card__name-wrap {
  flex: 1 1;
  display: flex;
  align-items: center;
  gap: 15px;
  width: 250px;
  min-width: 250px;
}

.user-card__img-wrap {
  position: relative;
  height: fit-content;
}

.user-card__img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
}

.user-card__img-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Oswald';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #fff;
}

.user-card__modal-img-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Oswald';
  font-size: 54px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #fff;
}

.user-card__name {
  min-width: 100px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
}

.user-card__training {
  flex: 1 1;
  width: 160px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--customColor);
}

.user-card__days {
  flex: 1 1;
  width: 120px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--customColor);
}

.user-card__expire {
  flex: 1 1;
  width: 120px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--customColor);
}

.user-card__btn-wrap {
  flex: 1 1;
}

.user-card__btn {
  min-width: 200px;
  padding: 7px 20px;
}

.user-card__btn-more {
  width: 50px;
}

.user-card__btn-more-img {
  width: 20px;
  transform: rotate(90deg);
}

.user-card__modal-img-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid var(--accentColor2);
}

.user-card__modal-img {
  width: 82px;
  height: 82px;
  border-radius: 8px;
}

.user-card__modal-text-wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.user-card__mobile-text-img-wrap {
  display: flex;
  gap: 10px;
}

.user-card__modal-text-name {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  margin-bottom: 15px;
  color: var(--primaryColor);
}

.user-card__modal-text {
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--customColor);
}

.user-card__modal-text--phone {
}

.user-card__modal-info-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  width: 100%;
  max-width: 300px;
  margin-top: 15px;
}

.user-card__modal-info {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.user-card__modal-info-title {
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--primaryColor);
  white-space: nowrap;
}

.user-card__modal-info-text {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--customColor);
  white-space: nowrap;
}

.user-card__mobile--main {
  display: flex;
  flex-direction: column;
}

.user-card__mobile-text--main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
}

.user-card__btn-mobile-more {
  width: 16px;
  height: 16px;
  margin-top: 3px;
}

.user-card__modal-archive-text {
  color: var(--customColor);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  margin-bottom: 20px;
  text-align: center;
}

.user-card__modal-archive-trainings-wrap {
  height: calc(100vh - 539px);
  overflow: auto;
  padding-right: 10px;
  border-bottom: 1px solid var(--accentColor2);
}

.user-card__modal-archive-trainings {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-bottom: 5px;
}

.user-card__modal-archive-trainings:not(:last-child) {
  border-bottom: 1px solid var(--accentColor2);
}

.user-card__modal-archive-trainings-num {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  color: var(--customColor);
}

.user-card__modal-archive-trainings-text {
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--customColor);
}

.user-card__modal-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 15px;
}

@media (min-width: 520px) {
  .user-card__modal-img {
    width: 100px;
    height: 100px;
  }
}
@media (min-width: 960px) {
  .user-card__modal-text-wrap {
    gap: 10px;
  }
  .user-card__modal-text-name {
    font-size: 20px;
  }
  .user-card__modal-text--phone {
    margin-bottom: 13px;
  }
}
