.time-progress {
  width: 100%;
  height: 10px;
  background-color: var(--accentColor2);
  border-radius: 4px;
  color: var(--primaryColor);
  overflow: hidden;
}

.time-progress__container {
  display: flex;
  height: 100%;
}

.progress-bar {
  width: 1%;
  height: 100%;
}

.progress-bar.filled {
  background-color: var(--accentColor);
}

.separator {
  width: 1px;
  height: 100%;
  background-color: #ffffff;
}

.time-progress__instractions-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-top: 15px;
  margin-bottom: 30px;
}

.time-progress__instractions-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 33%;
  color: var(--primaryColor);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  margin-bottom: -8px;
}

.time-progress__instractions-btn-img {
  width: 16px;
  height: 16px;
}

.time-progress__instractions-number-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  border-left: 1px solid var(--customColor);
  border-right: 1px solid var(--customColor);
}

.time-progress__instractions-number {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  color: var(--primaryColor);
}

.time-progress__instractions-text {
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--primaryColor);
}

.time-progress__instractions-sets-wrap {
  width: 33%;
  text-align: center;
}

.time-progress__btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.time-progress__btn-start {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  min-width: 140px;
  min-height: 41px;
}

.time-progress__instractions-number-line {
  display: inline-block;
  width: 1.5px;
  transform: rotate(13deg);
  height: 17px;
  background: var(--primaryColor);
  margin-left: 5px;
  margin-right: 5px;
}

.time-progress__name-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 25px;
}

.time-progress__name {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
}

.time-progress__name-time-wrap {
  min-width: 90px;
}

.time-progress__name-time {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
}

.time-progress__modal-img {
  width: 100%;
  max-width: 135px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 25px;
}

.time-progress__modal-title {
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: var(--accentColor);
}

.time-progress__modal-text {
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-top: 5px;
}

.time-progress__modal-btn-wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 25px;
  margin-top: 35px;
  margin-bottom: 10px;
}

.time-progress__name-time--active {
  letter-spacing: 1px;
}

.time-progress__name-time-seconds {
  letter-spacing: 1px;
}

@media (min-width: 960px) {
  .time-progress__btn-start {
    min-height: 51px;
  }
}
