.layout-home--wrpa {
  height: 100vh;
  overflow-y: auto;
  width: 100vw;
  overflow-x: hidden;
  color: var(--primaryColor);
}

.layout-home {
  position: relative;
  height: 100vh;
  margin: 0 auto;
}

.layout-home__header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}

.layout-home__header--wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 15px;
  margin: 0 auto;
}

.layout-home__header--fixed {
  position: fixed;
}

.layout-home__header--not-found {
  background: #1d1c1ccf;
}

.layout-home__header-wrap--fixed {
  padding: 15px 17px 15px 15px;
  background: #1d1c1ccf;
}

.layout-home__header-logo {
  width: 63px;
  height: auto;
  margin-right: 80px;
}

.layout-home__header-btn-wrap {
  display: flex;
  align-items: center;
  gap: 20px;
}

.layout-home__header-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  padding: 20px 25px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  color: #fff;
  border: 1px solid transparent;
  border-radius: 60px;
  white-space: nowrap;
}

.layout-home__header-btn--active {
  color: var(--highlightColor);
  border: 1px solid var(--highlightColor);
}

.layout-home__header-login-wrap {
  display: flex;
  align-items: center;
  gap: 25px;
}

.layout-home__header-register {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  color: #fff;
}

.layout-home__header-login {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 60px;
  padding: 13px 40px;
}

.layout-home__btn-scroll {
  position: fixed;
  z-index: 99;
  bottom: 15px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 11.3px;
  border: 2px solid #e6e2ff;
  width: 40px;
  height: 50px;
  transition: 0.3s;
}

.layout-home__btn-scroll-span {
  width: 20px;
  height: 20px;
  border-left: 6px solid var(--accentColor2);
  border-bottom: 6px solid var(--accentColor2);
  transform: rotate(135deg);
  margin-top: 7px;
  transition: 0.3s;
}

.layout-home__btn-scroll:hover {
  background: #e6e2ff;
}

.layout-home__btn-scroll:hover .layout-home__btn-scroll-span {
  border-left: 6px solid #fff;
  border-bottom: 6px solid #fff;
}

.layout-home__footer-wrap {
}

.layout-home__footer {
  border: 1px solid var(--accentColor2);
}

.layout-home__footer-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background: #f8f6ff;
  padding: 30px 15px;
}

.layout-home__footer-main-logo {
  width: 60px;
  height: auto;
}

.layout-home__footer-main-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.layout-home__footer-main-btn {
}

.layout-home__footer-main-btn-img {
  width: 28px;
  height: auto;
}

.layout-home__footer-main-link-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
}

.layout-home__footer-main-link {
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--customColor);
}

.layout-home__footer-bottom-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--accentColor2);
  background: #f8f6ff;
  padding-bottom: 65px;
}

.layout-home__footer-bottom {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  max-width: 1440px;
  padding: 10px 15px;
  border-top: 1px solid var(--accentColor2);
  background: #f8f6ff;
}

.layout-home__footer-bottom-text {
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.layout-home__footer-bottom-link-wrap {
  display: flex;
  align-items: center;
  gap: 20px;
}

.layout-home__footer-bottom-link {
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.layout-home___mobile-menu {
  position: relative;
  z-index: 100;
  cursor: pointer;
}

.layout-home___mobile-menu-bar1,
.layout-home___mobile-menu-bar2,
.layout-home___mobile-menu-bar3 {
  width: 35px;
  height: 5px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
}

.layout-home___mobile-menu--active .layout-home___mobile-menu-bar1 {
  transform: translate(0, 11px) rotate(-45deg);
}

.layout-home___mobile-menu--active .layout-home___mobile-menu-bar2 {
  opacity: 0;
}

.layout-home___mobile-menu--active .layout-home___mobile-menu-bar3 {
  transform: translate(0, -11px) rotate(45deg);
}

.layout-home__side-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.84);
  z-index: 7;
}

.layout-home__side-bar-link-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 100%;
  padding: 50px 15px;
}

.layout-home__side-bar-link--wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.layout-home__side-bar-login-wrap {
  display: flex;
  gap: 30px;
  align-items: center;
}

.layout-home__side-bar-link {
  color: #fff;
  font-size: 30px;
  transition: 0.3s;
}

.layout-home__side-bar-link:hover {
  color: var(--highlightColor);
}

.layout-home__side-bar-lang-wrap {
  position: absolute;
  top: 28px;
  left: 15px;
}

@media (min-width: 400px) {
  .layout-home__footer-main-link-wrap {
    gap: 15px;
  }
}

@media (min-width: 640px) {
  .layout-home__header-wrap--fixed {
    padding: 30px 27px 30px 25px;
  }
  .layout-home__header--wrap {
    padding: 15px 45px;
  }
  .layout-home__footer-main-link-wrap {
    gap: 30px;
  }
  .layout-home__footer-bottom {
    gap: 20px;
    padding: 20px 15px;
  }
  .layout-home__footer-bottom {
    flex-direction: row;
  }
  .layout-home__footer-bottom-wrap {
    padding-bottom: 0;
  }
  .layout-home__side-bar-lang-wrap {
    left: 45px;
  }
}

@media (min-width: 1300px) {
  .layout-home__header-btn {
    padding: 20px 40px;
  }
}

@media (min-width: 1680px) {
  .layout-home__btn-scroll {
    right: 50px;
  }
}
