.coaching-plan-view-wrap {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 460px;
  min-height: 0;
  padding: 0 0 15px 0;
}

.coaching-plan-view {
  flex: 1 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

.coaching-plan-view__video-wrap {
  height: 360px;
  width: 100%;
}

.coaching-plan-view__video {
  width: 100vw !important;
  max-width: 460px;
  transform: translate(-15px);
  background: #000 !important;
}

.coaching-plan-view__video-img-wrap {
  position: relative;
  position: absolute;
  top: 0;
  left: 15px;
  z-index: 2;
  width: 100vw !important;
  max-width: 460px;
  height: 360px;
  transform: translate(-15px);
  cursor: pointer;
}

.coaching-plan-view__video-img-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 50px;
  height: auto;
}

.coaching-plan-view__video-img {
}

.coaching-plan-view__info {
  max-height: calc(100dvh - 461px);
  overflow: auto;
  padding-right: 5px;
  margin-top: 15px;
}

.coaching-plan-view__btn-back {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 3;
}

.coaching-plan-view__btn-back-img {
  width: 40px;
  height: auto;
  padding: 6px;
}

.coaching-plan-view__video-img--no-photos {
  padding: 15px;
}
