.admin-footer {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100vw;
  margin-left: -15px;
  /* min-height: 85px; */
  min-height: 72px;
  background: rgb(245 255 224);
  z-index: 2;
}

.admin-footer--notifications {
  min-height: 65px;
}

.admin-footer__btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 33%;
  border-radius: 60px;
  transition: 0.3s;
  padding: 6.5px;
}

.admin-footer__btn--active {
  /* background: var(--highlightColor); */
}

.admin-footer__btn-text-wrap--active {
  font-weight: 500;
  scale: 1.18;
}

.admin-footer__btn-img-wrap {
}

.admin-footer__btn--active .admin-footer__btn-img-wrap {
  background: var(--highlightColor);
  border-radius: 60px;
  padding: 4px 20px;
}

.admin-footer__btn-img {
  width: 24px;
  height: 24px;
  opacity: 0.7;
  transition: 0.3s;
}

.admin-footer__btn--active .admin-footer__btn-img {
  opacity: 1;
}

.admin-footer__btn-text {
  font-size: 14px;
  color: var(--customColor);
  transition: 0.3s;
}

.admin-footer__btn-img--active {
  scale: 1.34;
}

.admin-footer__btn-text--active {
  color: var(--darkColor);
  font-weight: 500;
}

@media (min-width: 640px) {
  .admin-footer {
    display: none;
  }
}
