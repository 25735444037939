.home-view-wrap {
  color: var(--primaryColor);
  overflow: hidden;
}

.home-view {
  position: relative;
  max-width: 1440px;
}

.home-view__info-wrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.home-view__map-wrap {
  position: relative;
  padding: 60px 0 50px;
  margin-top: 40px;
}

.home-view__map-text-wrap {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  gap: 7px;
}

.home-view__map-title {
  font-size: 49px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  text-transform: uppercase;
  color: var(--accentColor2);
}

.home-view__map-text {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  color: var(--accentColor);
}

.home-view__map-img {
  width: 100%;
  height: auto;
}

.home-view__main-wrap {
  position: relative;
}

.home-view__main-bg {
  width: 100%;
  height: auto;
  height: 100dvh;
  min-height: 100dvh;
}

.home-view__main-bg--position-left {
  object-position: left;
}

.home-view__main-title-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
}

.home-view__main-title {
  font-size: 47px;
  font-style: normal;
  font-weight: 600;
  line-height: 70px;
  color: var(--highlightColor);
  text-transform: uppercase;
  white-space: wrap;
  text-align: center;
}

.home-view__main-sub-title {
  max-width: 330px;
  font-family: 'Montserrat';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #fff;
  text-align: center;
}

.home-view__main-btn {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 55px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  color: var(--primaryColor);
  background: var(--highlightColor);
  padding: 5px 40px;
  border-radius: 60px;
  white-space: nowrap;
}

.home-view__main-btn-img {
  width: 24px;
  height: 24px;
}

.home-view__available-wrap {
  margin-bottom: 70px;
}

@media (min-width: 450px) {
  .home-view__main-title {
    font-size: 56px;
  }
}

@media (min-width: 500px) {
  .home-view__map-title {
    font-size: 67px;
    line-height: 65px;
  }
}

@media (min-width: 640px) {
  .home-view__main-title {
    font-size: 80px;
    line-height: 95px;
  }
  .home-view__map-wrap {
    margin-top: 70px;
  }
  .home-view__map-title {
    font-size: 88px;
    line-height: 77px;
  }
}

@media (min-width: 768px) {
  .home-view__info-wrap {
    gap: 75px;
    margin-top: 75px;
    margin-bottom: 75px;
  }
  .home-view__map-text-wrap {
    gap: 30px;
  }
  .home-view__map-title {
    font-size: 111px;
    line-height: 62px;
  }
  .home-view__map-text {
    font-size: 48px;
    line-height: 30px;
  }
  .home-view__main-sub-title {
    font-size: 24px;
  }
}

@media (min-width: 960px) {
  .home-view__map-title {
    font-size: 136px;
    line-height: 114px;
  }
  .home-view__map-text {
    font-size: 48px;
    line-height: 65px;
  }
  .home-view__main-title {
    font-size: 96px;
    line-height: 115px;
  }
  .home-view__info-wrap {
    margin-top: 150px;
    margin-bottom: 150px;
  }
  .home-view__map-wrap {
    margin-top: 140px;
  }
  .home-view__available-wrap {
    margin-bottom: 140px;
  }
}

@media (min-width: 1200px) {
  .home-view__header-logo {
    margin-right: 163px;
  }
  .home-view__map-title {
    font-size: 180px;
    line-height: 150px;
  }
  .home-view__info-wrap {
    margin-bottom: 250px;
  }
  .home-view__available-wrap {
    margin-bottom: 250px;
  }
}

@media (min-width: 1440px) {
  .home-view__map-title {
    font-size: 215px;
    line-height: 166px;
  }
}

@media (min-width: 1680px) {
  .home-view__map-title {
    font-size: 256px;
    line-height: 230px;
  }
}
