.admin-trainings-wrap {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.admin-trainings__dialogform {
}

.admin-trainings__dialogform .MuiPaper-root {
  width: 90%;
  max-width: 460px;
  margin: 20px;
  background: #f2f2f2;
  border-radius: 27px;
}

.admin-trainings__dialogform-qr-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.admin-trainings__dialogform-name-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.admin-trainings__dialogform-name {
  font-size: 21.082px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.054px;
  color: var(--primaryColor);
}

.admin-trainings__dialogform-sub-title {
  font-family: Montserrat;
  font-size: 14.758px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--customColor);
}

.admin-trainings__dialogform-btn--qr {
  max-width: 150px;
}

.admin-trainings__dialogform-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.admin-trainings__dialogform-delete-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.admin-trainings__dialogform-delete-img {
  width: 80px;
  height: auto;
}

.admin-trainings__dialogform-delete-text {
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: var(--primaryColor);
  text-align: center;
}

.admin-trainings__dialogform-delete-text-name {
  font-size: 16px;
  font-weight: 600;
  margin-top: 5px;
}

.admin-trainings {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 15px;
}

.admin-trainings__main-wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 0px 0px;
  border-radius: 10px;
}

.admin-trainings__btn--wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.admin-trainings__btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  order: 3;
  margin-left: auto;
  margin-right: auto;
}

.admin-trainings__count-wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
  order: 1;
  min-width: 110.75px;
}

.admin-trainings__count {
  font-size: 20px;
  font-weight: 700;
  line-height: 16px;
  color: var(--primaryColor);
}

.admin-trainings__count-text {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  line-height: 16px;
  color: var(--customColor);
  white-space: nowrap;
}

.admin-trainings__price-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
  min-width: 110.75px;
  order: 2;
}

.admin-trainings__price {
  font-size: 20px;
  font-weight: 700;
  line-height: 16px;
  color: var(--primaryColor);
}

.admin-trainings__price-text {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  line-height: 16px;
  color: var(--customColor);
  white-space: nowrap;
}

.admin-trainings__add-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.admin-trainings__users--wrap {
  flex: 1 1;
  overflow: hidden;
}

.admin-trainings__users-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: calc(100dvh - 329px);
  margin-top: 20px;
  overflow: auto;
}

.admin-trainings__users {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.admin-trainings__users-header-wrap {
  display: none;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 99.8%;
  min-width: 815px;
  color: var(--customColor);
  font-size: 14px;
  font-weight: 400;
  background: #ffffff;
  white-space: nowrap;
}

.admin-trainings__users-header-name {
  flex: 1 1;
  padding-left: 111px;
  max-width: 300px;
  min-width: 300px;
}

.admin-trainings__users-header-days {
  width: 175px;
}

.admin-trainings__users-header-users {
  width: 175px;
}

.admin-trainings__users-header-qr-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 9px;
  width: 135px;
}

.admin-trainings__users-header-qr {
  width: 27px;
  text-align: end;
}

.admin-trainings__users-header-edit {
  width: 40px;
  text-align: end;
}

.admin-trainings__users-header-delete {
  width: 41px;
  text-align: end;
}

.admin-trainings__card {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--accentColor2);
  border-radius: 11px;
}

.admin-trainings__mobile-card-img-wrap {
  position: relative;
}

.admin-trainings__mobile-card-img {
  width: 100%;
  max-height: 280px;
  border-radius: 11px 11px 0 0;
  aspect-ratio: 16 / 9;
  border-radius: 11px 11px 0 0;
}

.admin-trainings__mobile-card-btn-forward {
  position: absolute;
  bottom: -21px;
  right: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #fff;
}

.admin-trainings__mobile-card-btn {
  position: absolute;
  bottom: -21px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #fff;
}

.admin-trainings__mobile-card-btn-img {
  width: 100%;
  height: auto;
}

.admin-trainings__mobile-card-text {
  padding: 15px;
}

.admin-trainings__mobile-card-text-title {
  color: var(--primaryColor);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.8px;
}

.admin-trainings__mobile-card-text-users {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.222px;
  letter-spacing: 0.4px;
  color: var(--customColor);
}

.admin-trainings__mobile-card-text-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.admin-trainings__mobile-card-text-days {
  height: 32px;
  border: 1px solid var(--accentColor2);
  color: var(--primaryColor);
  border-radius: 4px;
  padding: 5px 15px;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
}

.admin-trainings__mobile-card-text-btn--wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.admin-trainings__mobile-card-text-btn {
  width: 80px;
  height: 32px;
  border-radius: 91px;
  padding: 7px 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.admin-trainings__mobile-card-text-btn--edit {
  background: var(--highlightColor);
  color: var(--darkColor);
}

.admin-trainings__mobile-card-text-btn--delete {
  color: var(--customColor);
}

.admin-trainings__card-num {
  min-width: 25px;
  font-size: 20px;
  font-weight: 600;
  color: var(--customColor);
}

.admin-trainings__card-img {
  width: 56px;
  height: auto;
  max-height: 40px;
  aspect-ratio: 1.8 / 1;
}

.admin-trainings__title-wrap {
  flex: 1 1;
  display: flex;
  align-items: center;
  gap: 15px;
  max-width: 300px;
  min-width: 300px;
  color: var(--customColor);
}

.admin-trainings__title {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: var(--primaryColor);
  white-space: pre-wrap;
}

.admin-trainings__card-days {
  width: 175px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--customColor);
}

.admin-trainings__card-users {
  width: 175px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--customColor);
}

.admin-trainings__card-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 9px;
}

.admin-trainings__card-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 7px;
}

.admin-trainings__card-btn-img {
  width: 25px;
  height: auto;
}

.admin-trainings__card-arrow {
  width: 33px;
  height: auto;
}

.admin-trainings__empty-img {
  width: 100%;
  height: auto;
  max-width: 650px;
  min-height: 200px;
  max-height: calc(100vh - 418px);
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 640px) {
  .admin-trainings {
    padding-bottom: 0;
  }
  .admin-trainings__main-wrap {
    padding: 35px 15px 30px 30px;
    border: 1px solid var(--accentColor2);
    border-radius: 10px;
  }
  .admin-trainings__count-wrap,
  .admin-trainings__price-wrap {
    order: 0;
    min-width: 160px;
  }
  .admin-trainings__btn-wrap {
    width: fit-content;
    order: 0;
  }
  .admin-trainings__count {
    font-size: 32px;
    line-height: 25px;
  }
  .admin-trainings__count-text {
    font-size: 14px;
    line-height: 25px;
  }
  .admin-trainings__price {
    font-size: 32px;
    line-height: 25px;
  }
  .admin-trainings__price-text {
    font-size: 14px;
    line-height: 25px;
  }
  .admin-trainings__users-header-wrap {
    display: flex;
  }
  .admin-trainings__users-wrap {
    max-height: calc(100vh - 322px);
    gap: 0;
    padding-right: 20px;
  }
  .admin-trainings__users {
    gap: 0;
  }
  .admin-trainings__card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    min-width: fit-content;
    border-bottom: 1px solid var(--accentColor2);
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 16px 0;
    border-radius: 0;
    white-space: nowrap;
  }
  .admin-trainings__card-arrow {
    width: 21px;
  }
  .admin-trainings__btn--wrap {
    flex-wrap: nowrap;
    overflow: auto;
  }
}
