.available-bloc {
}

.available-bloc__title {
  font-size: 68px;
  font-style: normal;
  font-weight: 600;
  line-height: 67px;
  text-transform: uppercase;
  color: var(--accentColor2);
  text-align: center;
  margin-bottom: 30px;
}

.available-bloc__flags {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.available-bloc__flag {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 15px;
  border-radius: 8.835px;
  border: 1px solid var(--accentColor2);
}

.available-bloc__flag-img {
  width: 100%;
  max-width: 87px;
  height: auto;
  border-radius: 6.938px;
}

.available-bloc__flag-name {
  font-size: 12.369px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.32px;
  letter-spacing: 0.53px;
  color: var(--primaryColor);
}

@media (min-width: 768px) {
  .available-bloc__title {
    font-size: 145px;
    line-height: 133px;
  }
  .available-bloc__flags {
    gap: 30px;
  }
}

@media (min-width: 960px) {
  .available-bloc__title {
    font-size: 176px;
    line-height: 178px;
    margin-bottom: 60px;
  }
  .available-bloc__flags {
    gap: 40px;
  }
}

@media (min-width: 1200px) {
  .available-bloc__title {
    font-size: 200px;
  }
}

@media (min-width: 1440px) {
  .available-bloc__title {
    font-size: 256px;
    line-height: 230px;
  }
}
