.loader-wrap {
  position: fixed;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
  height: 100vh;
  background: #fff;
  margin-left: -15px;
}

.loader {
  width: 60px;
  aspect-ratio: 1;
  margin-top: -90px;
  /* margin-left: -15px; */
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #8f7ff3 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, #8f7ff3);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}

.loader--new-style {
  margin-left: 0;
}

.loader__progress-wrap {
  position: relative;
  min-width: 190px;
}

.loader__progress {
  position: absolute;
  bottom: -15px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: end;
  white-space: nowrap;
}

@media (min-width: 640px) {
  .loader-wrap {
    height: calc(100dvh - 60px);
  }
  .loader {
    margin-left: -130px;
  }
  .loader--new-style {
    margin-left: 0;
  }
  .loader__progress {
    left: -65px;
  }
}
