.user-profile-wrap {
  margin: 0 auto;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.user-profile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.user-profile__input--wrap {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 460px;
  min-height: 0;
  margin: 0 auto;
  padding: 23px 0;
  overflow: auto;
}

.user-profile__input-wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.user-profile__input-btn-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
}

.user-profile__btn-loader {
  left: 50%;
  margin-left: -15px;
}

.user-profile__input-btn {
  width: 50%;
  min-width: 100px;
}

.user-profile__input-container {
  width: 100%;
}

.user-profile__modal-input-wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.user-profile__input-password-wrap {
  position: relative;
  display: flex;
  align-items: center;
  gap: 25px;
  width: 100%;
}

.user-profile__mobile-img--wrap {
  align-items: center;
  display: flex;
  gap: 30px;
  justify-content: center;
  margin-bottom: 30px;
}

.user-profile__mobile-img-wrap {
  position: relative;
}

.user-profile__img-wrap {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.user-profile__input-img {
  width: 150px;
  height: 150px;
  border-radius: 10px;
}

.user-profile__img-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Oswald';
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #fff;
}

.user-profile__mobile-img-btn-wrap {
  position: absolute;
  bottom: 3px;
  right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.user-profile__mobile-img-btn-wrap-2 {
  position: absolute;
  bottom: 3px;
  right: -40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.user-profile__mobile-img-btn {
  width: 20px;
  height: auto;
}

.user-profile__info-tab-wrap {
  margin-bottom: 20px !important;
}

.user-profile__transactions-wrap {
  height: calc(100vh - 467px) !important;
  padding-right: 10px;
}

.user-profile__transactions-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-width: 90px;
  max-height: 30px;
  padding: 4px 15px;
}

.user-profile__no-transactions {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
  color: var(--primaryColor);
  text-align: center;
}

@media (min-width: 640px) {
}
