.coaching-rest {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100dvh - 165px);
  color: var(--primaryColor);
}

.coaching-rest__main {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.coaching-rest__time {
  min-width: 182px;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  margin-top: 35px;
  margin-bottom: 20px;
  text-align: center;
}

.coaching-rest__title {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
}

.coaching-rest__btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 41px;
}

.coaching-rest__hour {
  --c1: #0a1135;
  --c2: #8f7ff3;
  width: 50px;
  height: 80px;
  border-top: 3px solid var(--c1);
  border-bottom: 3px solid var(--c1);
  background: linear-gradient(90deg, var(--c1) 3px, var(--c2) 0 5px, var(--c1) 0) 50%/7px 8px no-repeat;
  display: grid;
  overflow: hidden;
}

.coaching-rest__hour.animated {
  animation: l5-0 5s infinite ease-out;
}

.coaching-rest__hour::before,
.coaching-rest__hour::after {
  content: '';
  grid-area: 1/1;
  width: 75%;
  height: calc(50% - 4px);
  margin: 0 auto;
  border: 3px solid var(--c1);
  border-top: 0;
  box-sizing: content-box;
  border-radius: 0 0 50% 50%;
  -webkit-mask:
    linear-gradient(#000 0 0) bottom/4px 2px no-repeat,
    linear-gradient(#000 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  background:
    linear-gradient(var(--d, 0deg), var(--c2) 50%, #0000 0) bottom / 100% 205%,
    linear-gradient(var(--c2) 0 0) center/0 100%;
  background-repeat: no-repeat;
}

.coaching-rest__hour.animated::before,
.coaching-rest__hour.animated::after {
  animation: l5-1 5s infinite linear;
}

.coaching-rest__hour::after {
  transform-origin: 50% calc(100% + 2px);
  transform: scaleY(-1);
  --s: 3px;
  --d: 180deg;
}

@keyframes l5-0 {
  80% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0.5turn);
  }
}

@keyframes l5-1 {
  10%,
  70% {
    background-size:
      100% 185%,
      var(--s, 0) 100%;
  }
  90%,
  100% {
    background-position: top, center;
  }
}
