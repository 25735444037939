.coaching-view-wrap {
  margin: 0 auto;
  width: 100%;
  max-width: 460px;
  padding: 15px 0;
}

.coaching-view {
}

.coaching-view__day-wrap {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.coaching-view__modal-text-wrap {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.coaching-view__modal-text {
  text-align: center;
  font-size: 22px;
}
