.admin-notifications-wrap {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.admin-notifications {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 0px 0;
  border-radius: 10px;
}

.admin-notifications__btn--wrap {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
}

.admin-notifications__btn-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.admin-notifications__count-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  min-width: 100px;
}

.admin-notifications__count {
  font-size: 20px;
  font-weight: 700;
  color: var(--primaryColor);
  line-height: 16px;
  text-align: center;
}

.admin-notifications__count-text {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  line-height: 16px;
  color: var(--customColor);
  white-space: nowrap;
}

.admin-notifications__price-wrap {
  min-width: 100px;
}

.admin-notifications__content-items-wrap {
  flex: 1 1;
  overflow: hidden;
}

.admin-notifications__content-items {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: calc(100dvh - 295px);
  margin-top: 20px;
  overflow: auto;
}

.admin-notifications__content-items--notifications {
  height: calc(100dvh - 258px);
}

.admin-notifications__content-item-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.admin-notifications__content-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid var(--accentColor2);
  border-radius: 10px;
  padding-bottom: 15px;
  padding-right: 5px;
  padding: 15px;
  font-family: 'Montserrat', sans-serif;
}

.admin-notifications__mobile-card-title--wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.admin-notifications__mobile-card-title-wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.admin-notifications__mobile-card-title {
  flex: 1 1;
  font-size: 14px;
  color: rgba(29, 27, 32, 1);
}

.admin-notifications__mobile-card-days-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
}

.admin-notifications__mobile-card-days {
  font-size: 12px;
  color: rgba(128, 128, 128, 1);
}

.admin-notifications__content-item-img-wrap {
  position: relative;
}

.admin-notifications__content-item-img {
  width: 50px;
  height: 50px;
  border-radius: 8px;
}

.admin-notifications__content-item-img-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Oswald';
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #fff;
}

.admin-notifications__content-item-text-wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.admin-notifications__content-item-text {
  font-size: 15px;
  font-weight: 500;
  color: var(--customColor);
}

.admin-notifications__content-item-date {
  font-size: 12px;
  font-weight: 500;
  color: rgba(116, 116, 116, 1);
}

.admin-all-plans__empty {
  padding-top: 10px;
}

@media (min-width: 640px) {
  .admin-notifications {
    padding: 35px 15px 30px 30px;
    border: 1px solid var(--accentColor2);
    border-radius: 10px;
  }
  .admin-notifications__btn--wrap {
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow: auto;
  }
  .admin-notifications__count {
    font-size: 32px;
    text-align: start;
    line-height: 25px;
  }
  .admin-notifications__count-text {
    font-size: 14px;
    line-height: 25px;
  }
  .admin-notifications__btn-wrap {
    width: fit-content;
    position: absolute;
    left: 50%;
    top: 34%;
    transform: translate(-50%, -50%);
  }
  .admin-notifications__content-items {
    max-height: calc(100vh - 261px);
    padding-right: 15px;
  }
  .admin-notifications__content-item {
    flex-direction: row;
    border-bottom: 1px solid var(--accentColor2);
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    padding: 0 0 15px 0;
  }
  .admin-notifications__price-wrap,
  .admin-notifications__count-wrap {
    align-items: flex-start;
    min-width: 150px;
  }
}
