.coaching-plan-card {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--accentColor2);
  border-radius: 12px;
  transition: 0.3s;
}

.coaching-plan-card:hover {
  border: 1px solid var(--accentColor);
}

.coaching-plan-card__title-wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 15px;
}

.coaching-plan-card__title {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  color: var(--primaryColor);
}

.coaching-plan-card__sub-title {
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--customColor);
}

.coaching-plan-card__img-wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-right: 1px solid var(--accentColor2);
}

.coaching-plan-card__img {
  width: 100%;
  height: 100%;
  border-radius: 12px 0 0 12px;
}

.coaching-plan-card__video {
  width: 80px !important;
  height: 80px !important;
}

.coaching-plan-card__video .react-player__preview,
.coaching-plan-card__video iframe {
  border-radius: 12px 0 0 12px !important;
}

.coaching-plan-card__img--no-photos {
  padding: 20px;
}

.coaching-plan-card__video-img-play {
  position: absolute;
  top: 50%;
  left: 53%;
  transform: translate(-50%, -50%);
  width: 32px;
  height: auto;
}

.coaching-plan-card__img-clock {
  width: 24px;
  height: auto;
}
