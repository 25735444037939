.admin-all-plans-wrap {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.admin-all-plans__modal-input-wrap {
}

.admin-all-plans {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 15px;
}

.admin-all-plans__main-wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 0px 0px;
  border-radius: 10px;
}

.admin-all-plans__btn--wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.admin-all-plans__btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  order: 3;
  margin-left: auto;
  margin-right: auto;
}

.admin-all-plans__count-wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
  order: 1;
}

.admin-all-plans__count {
  font-size: 20px;
  font-weight: 700;
  color: var(--primaryColor);
  line-height: 16px;
}

.admin-all-plans__count-text {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  line-height: 16px;
  color: var(--customColor);
  white-space: nowrap;
}

.admin-all-plans__price-wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
  order: 2;
  min-width: 110.75px;
}

.admin-all-plans__price {
  font-size: 20px;
  font-weight: 700;
  line-height: 16px;
  color: var(--primaryColor);
  text-align: end;
}

.admin-all-plans__price-text {
  font-size: 12px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  line-height: 16px;
  color: var(--customColor);
  white-space: nowrap;
  text-align: end;
}

.admin-all-plans__add-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.admin-all-plans__users--wrap {
  flex: 1 1;
  overflow: hidden;
}

.admin-all-plans__users-wrap {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: calc(100dvh - 329px);
  margin-top: 20px;
  overflow: auto;
}

.admin-all-plans__users {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.admin-all-plans__users-header-wrap {
  display: none;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 99.8%;
  min-width: 525px;
  color: var(--customColor);
  font-size: 14px;
  font-weight: 400;
  background: #ffffff;
  white-space: nowrap;
}

.admin-all-plans__users-header-name {
  flex: 1 1;
  min-width: 300px;
}

.admin-all-plans__users-header-name-span {
  padding-left: 111px;
}

.admin-all-plans__users-header-days {
  flex: 1 1;
}

.admin-all-plans__users-header-qr-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 9px;
  width: 135px;
}

.admin-all-plans__users-header-qr {
  width: 47px;
  text-align: center;
}

.admin-all-plans__users-header-edit {
  width: 52px;
  text-align: center;
}

.admin-all-plans__users-header-delete {
  width: 41px;
  text-align: end;
}

.admin-all-plans__card {
  border: 1px solid var(--accentColor2);
  border-radius: 11px;
  padding: 15px;
  cursor: pointer;
}

.admin-all-plans__mobile-card-title--wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 30px;
}

.admin-all-plans__mobile-card-title-wrap {
}

.admin-all-plans__mobile-card-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  color: var(--primaryColor);
}

.admin-all-plans__mobile-card-days {
  font-family: 'Montserrat';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--customColor);
}

.admin-all-plans__mobile-card-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.admin-all-plans__mobile-card-btn {
  min-width: 80px;
  height: 32px;
  background: var(--primaryColor);
  color: rgba(255, 255, 255, 1);
  border: 1px solid var(--primaryColor);
  border-radius: 91px;
  padding: 3px 15px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
}

.admin-all-plans__mobile-card-btn--delete {
  background: inherit;
  color: var(--primaryColor);
}

.admin-all-plans__card-num {
  min-width: 25px;
  font-size: 20px;
  font-weight: 600;
  color: var(--customColor);
}

.admin-all-plans__card-img {
  width: 56px;
  height: auto;
  max-height: 40px;
  aspect-ratio: 1.27 / 1;
}

.admin-all-plans__card-img--no-photos {
  object-fit: contain;
}

.admin-all-plans__card-title-wrap {
  flex: 1 1;
  display: flex;
  align-items: center;
  gap: 15px;
  min-width: 300px;
  color: var(--customColor);
}

.admin-all-plans__title {
  flex: 1 1;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: var(--primaryColor);
}

.admin-all-plans__card-days {
  flex: 1 1;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--customColor);
}

.admin-all-plans__card-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 9px;
}

.admin-all-plans__card-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 7px;
}

.admin-all-plans__card-btn-img {
  width: 25px;
  height: auto;
}

.admin-all-plans__empty--wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.admin-all-plans__empty-img {
  width: 200px;
  height: auto;
}

.admin-all-plans__empty-wrap {
  font-size: 20px;
  font-weight: 600;
  color: var(--customColor);
  text-align: center;
  padding: 30px 0 0 0;
}

.admin-all-plans__empty-img-wrap {
  max-height: calc(100dvh - 252px);
}

@media (min-width: 640px) {
  .admin-all-plans {
    padding-bottom: 0;
  }
  .admin-all-plans__main-wrap {
    padding: 35px 15px 30px 30px;
    border: 1px solid var(--accentColor2);
    border-radius: 10px;
  }
  .admin-all-plans__count-wrap,
  .admin-all-plans__price-wrap {
    order: 0;
    min-width: 160px;
  }
  .admin-all-plans__btn-wrap {
    width: fit-content;
    margin-left: 0;
    margin-right: 0;
    order: 0;
  }
  .admin-all-plans__count {
    font-size: 32px;
    line-height: 25px;
  }
  .admin-all-plans__count-text {
    font-size: 14px;
    line-height: 25px;
  }
  .admin-all-plans__price {
    font-size: 32px;
    line-height: 25px;
  }
  .admin-all-plans__price-text {
    font-size: 14px;
    line-height: 25px;
  }
  .admin-all-plans__users-header-wrap {
    display: flex;
  }
  .admin-all-plans__card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    min-width: fit-content;
    border-bottom: 1px solid var(--accentColor2);
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    padding: 15px 0;
    white-space: nowrap;
  }
  .admin-all-plans__users-wrap {
    gap: 0;
    max-height: calc(100vh - 332px);
    padding-right: 20px;
  }
  .admin-all-plans__users {
    gap: 0;
  }
  .admin-all-plans__btn--wrap {
    flex-wrap: nowrap;
    overflow: auto;
  }
}
