.not-found {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.not-found--wrap {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.not-found__main {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 40px 15px;
}

.not-found__main-img {
  height: auto;
  width: 100%;
  max-width: 420px;
}

.not-found--home {
  height: auto;
  padding-top: 90px;
  background: #f8f6ff;
}

@media (min-width: 960px) {
}
