.coaches-view-wrap {
  color: var(--primaryColor);
}

.coaches-view {
  position: relative;
  max-width: 1440px;
}

.coaches-view__comments-wrap {
  margin-bottom: 100px;
}

.coaches-view__info-img {
  max-width: 100% !important;
  border-radius: 4px !important;
}

.coaches-view__info-title {
  font-size: 68px;
  font-style: normal;
  font-weight: 600;
  line-height: 68px;
  color: var(--accentColor2);
}

.coaches-view__info-video {
  width: 100% !important;
  max-width: 940px !important;
  height: auto !important;
  margin-bottom: 60px;
}

.coaches-view__info-video video {
  width: 100% !important;
  max-width: 940px !important;
  height: auto !important;
  border-radius: 22px !important;
  box-shadow: 0px 0px 120px 0px rgba(10, 17, 53, 0.1);
}

@media (min-width: 640px) {
  .coaches-view__info-title {
    font-size: 130px;
    line-height: 130px;
  }
}

@media (min-width: 768px) {
  .coaches-view__info-title {
    font-size: 145px;
    line-height: 133px;
  }
}

@media (min-width: 960px) {
  .coaches-view__info-title {
    font-size: 200px;
    line-height: 178px;
  }
}
