.layout {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-width: 100vw;
  max-height: 100dvh;
  min-height: 100dvh;
  overflow: auto;
  z-index: 100;
}

.layout__outlet-wrap {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  min-height: 0;
}

.layout__modal-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

@media (min-width: 960px) {
  .layout {
    padding-bottom: 0;
  }
}
