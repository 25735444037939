.sign-up-wrap {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  height: 100vh;
  overflow: hidden;
}

.sign-up {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-height: 0;
  font-size: var(--text-size);
}

.sign-up__form-wrap {
  position: relative;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 100vh;
  overflow: auto;
  padding: 20px 0 65px;
}

.sign-up__form-btn-back {
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
}

.sign-up__form-btn-back-img {
  width: 27px;
  height: auto;
}

.sign-up__form-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
}

.sign-up__form {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 460px;
}

.sign-up__form-google-btn-wrap {
  position: relative;
  display: block;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.sign-up__form-google-btn-img {
  width: 100%;
}

.sign-up__form-google-btn {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 1 !important;
  opacity: 0 !important;
}

.sign-up__form-user-btn-wrap {
  display: block;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.sign-up__form-user-btn-wrap--mob {
  width: fit-content;
  margin-bottom: 30px;
}

.sign-up__form-input-wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding-top: 5px;
  padding-right: 5px;
}

.sign-up__form-input-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  height: calc(100vh - 358px);
  overflow: auto;
  padding-top: 5px;
}

.sign-up__form-input-container--desk {
  height: auto;
}

.sign-up__form-phone-wrap {
  width: 100%;
}

.sign-up__form-phone {
  width: 100%;
  font-family: 'Montserrat', sans-serif !important;
  margin-left: auto;
  margin-right: auto;
}

.sign-up__form-phone .form-control {
  font-family: 'Montserrat', sans-serif !important;
  width: 100%;
  height: 56px;
  background: inherit;
  border: 1px solid var(--accentColor2);
}

.sign-up__form-phone:hover .flag-dropdown,
.sign-up__form-phone:hover .form-control {
  border: 1px solid var(--accentColor);
}

.sign-up__form-phone .flag-dropdown {
  border: 1px solid var(--accentColor2);
  background-color: #ffffff;
}

.sign-up__form-phone-error {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: red;
}

.sign-up__form-btn-wrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: flex-end;
}

.sign-up__form-input-btn--wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
}

.sign-up__form-btn-create {
  font-size: 16px;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  color: var(--primaryColor);
}

.sign-up__desktop {
  flex: 1 1;
  display: flex;
  height: 100vh;
}

.sign-up__desktop-img-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  background: rgba(242, 242, 242, 1);
}

.sign-up__desktop-img-logo {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 78px;
  height: auto;
  margin-bottom: 30px;
}

.sign-up__desktop-img {
  width: 100%;
  height: 100vh;
  object-position: top;
}

.sign-up__desktop-form {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 460px;
  padding: 60px 0;
}

.sign-up__desktop-form-title {
  align-self: flex-start;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  color: var(--primaryColor);
  margin-bottom: 13px;
  text-transform: capitalize;
}

.sign-up__desktop-form-sub-title {
  align-self: flex-start;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--customColor);
  margin-bottom: 30px;
}

.sign-up__desktop-form-input-wrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 460px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
}

.sign-up__desktop-form-input-name-wrap {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
}

.sign-up__desktop-btn-wrap {
  width: 100%;
  margin-top: 35px;
}

.sign-up__desktop-form-or {
  width: 100%;
  border-bottom: 1px solid var(--accentColor2);
  margin-top: 20px;
  margin-bottom: 40px;
}

.sign-up__desktop-form-or-text {
  transform: translateY(46%);
  width: fit-content;
  font-size: 14px;
  font-weight: 500;
  color: var(--customColor);
  padding: 10px 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background: #fff;
  padding: 11px;
}

.sign-up__desktop-form-btn--wrap {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 5px;
  width: 100%;
  margin-top: 20px;
}

.sign-up__desktop-form-btn-text {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--customColor);
}

.sign-up__desktop-form-btn {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--customColor);
}

.sign-up__form-phone--is-active .flag-dropdown,
.sign-up__form-phone--is-active .form-control {
  border: 1px solid var(--accentColor);
}

.sign-up__user-login-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 100%;
}

.sign-up__user-login-qr-wrap {
  padding: 0 20px;
  border-radius: 10px;
  text-align: center;
}

.sign-up__user-login-btn-google-play-link {
  display: block;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.sign-up__user-login-qr-img {
  max-width: 270px;
  width: 100%;
  height: auto;
}

.sign-up__user-login-btn-google-play {
  width: 135px;
  height: auto;
  margin-top: 20px;
  margin-bottom: 40px;
}

.sign-up__form-btn-sign-up {
  text-transform: capitalize;
}

.sign-up__input-form--wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.sign-up__form-business-type-item {
  color: var(--customColor) !important;
  font-family: 'Montserrat' !important;
}

.sign-up__form-business-type-select .MuiSelect-select {
  color: var(--customColor) !important;
  font-family: 'Montserrat' !important;
}

.sign-up__btn-web-registration--margin {
  margin-top: 30px;
  margin-bottom: 20px;
}

.select-exercise__select .MuiPaper-root::-webkit-scrollbar {
  width: 8px !important;
}

.select-exercise__select .MuiPaper-root::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.select-exercise__select .MuiPaper-root::-webkit-scrollbar-thumb {
  background: var(--accentColor) !important;
  border-radius: 4px !important;
}

.select-exercise__select .MuiPaper-root::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.sign-up__modal-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: var(--primaryColor);
  margin-top: 30px;
  margin-bottom: 30px;
}

.sign-up__modal-text {
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: center;
  color: var(--primaryColor);
  margin-bottom: 30px;
}

@media (min-width: 960px) {
  .sign-up-wrap {
    padding: 0;
  }
  .sign-up__form-wrap {
    padding: 0;
  }
}
