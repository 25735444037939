.admin-profile-wrap {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.admin-profile {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 15px;
}

.admin-profile__mobile--wrap {
  padding-top: 16px;
}

.admin-profile__mobile-wrap {
  display: flex;
  flex-direction: column;
  height: calc(100dvh - 190px);
  overflow: auto;
  padding-top: 15px;
  padding-bottom: 2px;
}

.admin-profile__mobile-img--wrap {
  display: flex;
  gap: 15px;
}

.admin-profile__mobile-img-wrap {
  position: relative;
  width: fit-content;
  /* margin-left: auto; */
  /* margin-right: auto; */
  margin-bottom: 25px;
}

.admin-profile__mobile-img {
  width: 144px;
  height: 144px;
  border-radius: 10px;
}

.admin-profile__mobile-img-btn-wrap {
  position: absolute;
  bottom: 3px;
  right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.admin-profile__mobile-img-btn {
  width: 20px;
  height: auto;
}

.admin-profile__mobile-input-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.admin-profile__mobile-input-name-wrap {
  justify-content: flex-start;
  width: 100%;
}

.admin-profile__mobile-save-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  width: 100%;
}

.admin-profile__mobile-save-btn-wrap--margin {
  margin-top: 20px;
}

.admin-profile__input-wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
  max-height: calc(100dvh - 204px);
  padding-top: 1px;
  padding-right: 5px;
}

.admin-profile__input-img-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.admin-profile__input-img--wrap {
  position: relative;
}

.admin-profile__img-wrap {
  position: relative;
}

.admin-profile__input-img {
  width: 150px;
  height: 150px;
  border-radius: 10px;
}

.admin-profile__img-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Oswald';
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #fff;
}

.admin-profile__input-name-wrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding-top: 4px;
}

.admin-profile__add-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.admin-profile__input-password-wrap {
  position: relative;
  display: flex;
  align-items: center;
  gap: 25px;
  width: 100%;
}

.admin-profile__btn-edite {
  width: 56px;
  min-width: 56px;
  height: 56px;
  padding: 12.2px;
  border: 1px solid var(--accentColor);
  border-radius: 4px;
}

.admin-profile__modal-input-wrap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.admin-profile__btn-resend {
  position: absolute;
  top: 50%;
  right: 93px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--accentColor);
  white-space: nowrap;
  background-color: #fff;
  padding: 2px;
}

.admin-profile__btn-resend--disabled {
  pointer-events: none;
}

.admin-profile__info-tab-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  border-bottom: 1px solid rgba(192, 182, 255, 0.4);
  margin-bottom: 10px;
}

.admin-profile__info-tab {
  min-width: 80px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  text-align: center;
  color: var(---primaryColor);
  transition: 0.3s;
}

.admin-profile__info-tab--active {
  position: relative;
  color: var(--accentColor);
}

.admin-profile__info-tab--active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(--accentColor);
}

@media (min-width: 640px) {
  .admin-profile {
    padding-bottom: 0;
  }
  .admin-profile-wrap {
    padding-left: 10px;
  }
  .admin-profile__img-text {
    font-size: 96px;
  }
  .admin-profile__mobile-wrap {
    padding-right: 20px;
  }
  .admin-profile__mobile--wrap {
    padding-top: 0;
  }
}
