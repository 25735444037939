.select-language__select-lenguage {
}

.select-language__select-lenguage-items {
}

.select-language__select-lenguage-items .MuiPaper-root {
  border: 1px solid var(--accentColor);
  border-radius: 4px;
  padding: 0 5.5px;
  box-shadow: none;
}

.select-language__select-lenguage-item {
  padding: 3px !important;
  min-height: auto !important;
}

.select-language__select-lenguage-item:not(:last-child) {
  border-bottom: 1px solid var(--accentColor2) !important;
}

.select-language__select-lenguage .MuiSvgIcon-root {
  display: none !important;
}

.select-language__select-lenguage .MuiSelect-select {
  min-height: 29px !important;
  padding-right: 0 !important;
  padding: 0;
  text-align: center;
  font-family: 'Oswald';
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 1.8px;
  color: var(--primaryColor);
}

.select-language__select-lenguage .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.layout-home__select-lenguage {
}

.layout-home__select-lenguage .MuiSelect-select {
  color: #fff !important;
  min-height: 35px !important;
  font-size: 35px !important;
}

.sign-in__desktop-select-lenguage {
  align-self: flex-end;
}

@media (min-width: 1150px) {
  .layout-home__select-lenguage .MuiSelect-select {
    min-height: 29px !important;
    font-size: 21px !important;
  }
}
