.products-view-wrap {
  color: var(--primaryColor);
}

.products-view {
  position: relative;
  max-width: 1440px;
}

.products-view__main-video {
  width: calc(100vw - 6px) !important;
  height: auto !important;
}

.products-view__main-video video {
  height: 73vh !important;
  object-fit: cover !important;
}

.products-view__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.products-view__info-title {
  /* font-size: 60px; */
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: 90px;
  color: var(--accentColor2);
}

.products-view__info-img {
  width: 100%;
  max-width: 260px;
  height: auto;
  margin-bottom: 60px;
  border-radius: 22px;
  box-shadow: 0px 0px 120px 0px rgba(10, 17, 53, 0.1);
}

.products-view__info-video {
  width: 260px !important;
  max-width: 260px;
  height: auto !important;
  margin-bottom: 60px;
  border-radius: 22px !important;
  background: transparent !important;
}

.products-view__info-video video {
  border-radius: 22px !important;
  box-shadow: 0px 0px 120px 0px rgba(10, 17, 53, 0.1);
}

.products-view__info-text {
  width: 100%;
  max-width: 495px;
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  color: var(--customColor2);
}

.products-view__comments-wrap {
  margin-bottom: 120px;
}

@media (min-width: 640px) {
  .products-view__main-video video {
    height: 100vh !important;
    object-fit: cover !important;
  }
  .products-view__info {
    margin-top: 200px;
    margin-bottom: 200px;
  }
  .products-view__info-img {
    margin-top: -20px;
  }
  .products-view__info-video {
    margin-top: -20px !important;
  }
  .products-view__info-title {
    font-size: 94px;
    line-height: 94px;
  }
}

@media (min-width: 768px) {
  .products-view__info-title {
    font-size: 114px;
    line-height: 114px;
  }
}

@media (min-width: 850px) {
  .products-view__info-title {
    font-size: 126px;
    line-height: 126px;
  }
}

@media (min-width: 960px) {
  .products-view__info-title {
    font-size: 142px;
    line-height: 142px;
  }
  .products-view__info-img {
    margin-top: -27px;
  }
  .products-view__info-video {
    margin-top: -32px !important;
  }
}

@media (min-width: 1200px) {
  .products-view__info-title {
    font-size: 180px;
    line-height: 178px;
  }
  .products-view__info-img {
    margin-top: -30px;
  }
  .products-view__info-video {
    margin-top: -30px !important;
  }
}

@media (min-width: 1440px) {
  .products-view__info-title {
    font-size: 200px;
    line-height: 178px;
  }
}
