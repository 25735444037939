.coaching-card {
  border: 1px solid var(--borderColor);
  border-radius: 11px;
  color: var(--primaryColor);
  transition: 0.3s;
}

.coaching-card:hover {
  border: 1px solid var(--accentColor);
}

.coaching-card__img-wrap {
  position: relative;
}

.coaching-card__img {
  width: 100%;
  max-width: 460px;
  aspect-ratio: 1.8/1;
  border-radius: 11px 11px 0 0;
}

.coaching-card__title--wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
  padding: 15px;
}

.coaching-card__title-wrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.coaching-card__title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.867px;
  letter-spacing: 0.8px;
  color: var(--primaryColor);
}

.coaching-card__sub-title {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.222px;
  letter-spacing: 0.4px;
  color: var(--customColor2);
}

.coaching-card__description {
  position: absolute;
  bottom: 15px;
  left: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-wrap: wrap;
}

.coaching-card__description--wrap {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.coaching-card__description-block {
  font-family: 'Montserrat';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  padding: 3px 10px;
  color: var(--primaryColor);
  border: 1px solid var(--borderColor);
  border-radius: 8.4px;
  background: rgba(255, 255, 255, 1);
}

.coaching-card__start-btn-wrap {
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  gap: 10px;
}

.coaching-card__start-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18.222px;
  letter-spacing: 0.4px;
  background: var(--highlightColor);
  border-radius: 100px;
  color: var(--primaryColor);
  border: 1px solid var(--highlightColor);
  padding: 6.5px 25px;
  margin-left: auto;
  transition: 0.3s;
}

.coaching-card__start-btn:hover {
  background: var(--accentColor);
  border: 1px solid var(--accentColor);
  color: #fff;
}

.coaching-card__start-btn-pay {
  background: var(--primaryColor);
  border: 1px solid var(--primaryColor);
  color: #ffffff;
}

.coaching-card__start-btn-pay:disabled {
  background: var(--primaryColor);
  border: 1px solid var(--primaryColor);
  color: #ffffff;
  opacity: 0.6;
}

.coaching-card__start-btn-cancel {
  background: transparent;
  border: 1px solid var(--primaryColor);
}

.coaching-card__img--paid {
  cursor: pointer;
}

.coaching-card__hour {
  position: relative;
  width: 25px;
  height: 31px;
}

.coaching-card__loader {
  width: 40px;
  margin-left: -13px;
  margin-top: -6px;
}
