.admin-training-wrap {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.admin-training {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 15px;
}

.admin-training__input-wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 0 0;
}

.admin-training__input--wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 5px;
  width: 100%;
  max-height: calc(100dvh - 218px);
  overflow: auto;
  padding-right: 5px;
  margin-left: auto;
  margin-right: auto;
}

.admin-training__btn--wrap {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.admin-training__count-wrap {
  display: none;
  flex-direction: column;
  gap: 5px;
  width: 85px;
}

.admin-training__btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.admin-training__price-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  order: 2;
  margin-left: auto;
  margin-right: auto;
}

.admin-training__price {
  font-size: 20px;
  font-weight: 700;
  line-height: 16px;
  color: var(--primaryColor);
  text-align: end;
}

.admin-training__price-text {
  font-size: 10px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  line-height: 16px;
  color: var(--customColor);
  white-space: nowrap;
}

.admin-training__add-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.admin-training__days--wrap {
  flex: 1 1;
}

.admin-training__days-wrap {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 327px);
  overflow: auto;
  padding-right: 5px;
}

.admin-training__days-header-wrap {
  display: none;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  min-width: 815px;
  margin-bottom: 2px;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.7px;
  color: var(--customColor);
  background-color: #fff;
}

.admin-training__days-header-name {
  flex: 1 1;
  padding-left: 76px;
}

.admin-training__days-header-days {
  flex: 1 1;
}

.admin-training__days-header-amount {
  flex: 1 1;
}

.admin-training__days-header-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  width: 135px;
}

.admin-training__days-header-copy {
  width: 53px;
  text-align: center;
}

.admin-training__days-header-edit {
  width: 56px;
  text-align: center;
}

.admin-training__days-header-delete {
  width: 55px;
  text-align: end;
}

.admin-training__mobile-card {
  border: 1px solid rgba(134, 134, 134, 1);
  border-radius: 11px;
  padding: 15px;
  margin-bottom: 15px;
  background: #fff;
}

.admin-training__mobile-card-title--wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 30px;
}

.admin-training__mobile-card-title-wrap {
}

.admin-training__mobile-card-title {
  font-size: 14px;
  color: rgba(29, 27, 32, 1);
}

.admin-training__mobile-card-days-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.admin-training__mobile-card-days {
  font-size: 12px;
  color: rgba(128, 128, 128, 1);
  text-transform: lowercase;
}

.admin-training__mobile-card-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.admin-training__mobile-card-btn {
  min-width: 80px;
  height: 32px;
  background: var(--primaryColor);
  color: rgba(255, 255, 255, 1);
  border: 1px solid var(--primaryColor);
  border-radius: 91px;
  padding: 4px 15px;
  font-size: 12px;
}

.admin-training__mobile-card-btn--delete {
  background: inherit;
  color: var(--primaryColor);
}

.admin-training__days-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.admin-training__days-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  min-width: 815px;
  border-bottom: 1px solid var(--accentColor2);
  background: #ffffff;
  padding: 16px 0;
  white-space: nowrap;
}

.admin-training__days-card-num-wrap {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 20px;
  font-weight: 600;
  color: #5e5e5e;
}

.admin-training__days-card-num {
  min-width: 25px;
}

.admin-training__days-title {
  flex: 1 1;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.867px;
  letter-spacing: 0.6px;
  color: var(--primaryColor);
}

.admin-training__days-card-days {
  flex: 1 1;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-transform: lowercase;
  color: var(--customColor);
}

.admin-training__days-card-amount {
  flex: 1 1;
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-transform: lowercase;
  color: var(--customColor);
}

.admin-training__days-card-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 9px;
  width: 135px;
}

.admin-training__days-card-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 7px;
}

.admin-training__days-card-btn-img {
  width: 25px;
  height: auto;
}

.admin-training__add-btn-new-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.admin-training__create-img-cropper-wrap {
  flex: 1 1;
  position: relative;
  width: 100%;
}

.admin-training__modal-edite-image {
  display: flex;
  flex-direction: column;
  height: 87%;
}

.admin-training__modal-edite-image-slider {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.admin-training__modal-edite-image-slider-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.admin-training__modal-edite-image-slider-wrap .MuiTypography-root {
  line-height: 1.66;
}

.admin-training__modal-edite-image-slider-wrap .MuiSlider-root {
  padding: 9px 0;
}

.admin-training__modal-edite-slider {
  color: var(--accentColor) !important;
}

@media (min-width: 640px) {
  .admin-training {
    padding-bottom: 0;
  }
  .admin-training__count-wrap {
    display: flex;
  }
  .admin-training__input-wrap {
    padding: 35px 15px 30px 30px;
    border: 1px solid var(--accentColor2);
    border-radius: 10px;
    gap: 0;
  }
  .admin-training__btn-wrap {
    width: fit-content;
  }
  .admin-training__days-header-wrap {
    display: flex;
  }
  .admin-training__days--wrap {
    margin-top: 20px;
    max-height: calc(100vh - 322px);
    padding-right: 20px;
    overflow: auto;
  }
  .admin-training__create-img-result-name {
    font-size: 16px;
  }
  .admin-training__price {
    font-size: 32px;
    line-height: 25px;
  }
  .admin-training__price-text {
    font-size: 14px;
    line-height: 25px;
  }
  .admin-training__input--wrap {
    max-height: calc(100vh - 332px);
    overflow: auto;
    padding-right: 20px;
    margin-top: 20px;
  }
  .admin-training__days-wrap {
    padding-right: 0;
    max-height: none;
    overflow-x: hidden;
    min-width: 815px;
  }
  .admin-training__days-container {
    gap: 0;
  }
  .admin-training__modal-edite-image-slider-wrap .MuiTypography-root {
    line-height: 2.66;
  }
  .admin-training__modal-edite-image-slider-wrap {
    width: 47%;
  }
  .admin-training__btn--wrap {
    flex-direction: row;
  }
  .admin-training__price-wrap {
    margin-left: 0;
    margin-right: 0;
    align-items: flex-end;
  }
}
@media (min-width: 960px) {
  .admin-training__modal-edite-image {
    height: 85.5%;
  }
  .admin-training__modal-edite-image-slider {
    gap: 30px;
    margin-bottom: 20px;
  }
}
