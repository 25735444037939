.training-view-wrap {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  height: 100vh;
  overflow: auto;
  padding: 20px 0;
}

.training-view {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 460px;
  height: 100%;
  min-height: 0;
  font-size: var(--text-size);
}

.training-view__img {
  width: 100%;
  max-height: 200px;
}

.training-view__info {
  display: flex;
  flex-direction: column;
  height: calc(100dvh - 241px);
  overflow: auto;
  padding-right: 5px;
}

.training-view__content {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 15px;
}

.training-view__title {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32.4px;
  letter-spacing: 0.8px;
  color: var(--primaryColor);
  text-align: center;
  margin-top: 20px;
}

.training-view__content-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.867px;
  letter-spacing: 0.8px;
  color: var(--primaryColor);
}

.training-view__content-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.867px;
  letter-spacing: 0.8px;
  color: var(--primaryColor);
}

.training-view__add-wrap {
  flex: 1 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

.training-view__add-btn {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--accentColor);
}

.training-view__add-btn-text {
  width: fit-content;
  text-align: start;
}

.training-view__add-btn-img {
  width: 25px;
  height: 25px;
}
