.comments-bloc__coments {
  padding: 0 0 30px;
}

.comments-bloc__coments-title {
  font-size: 68px;
  font-style: normal;
  font-weight: 600;
  line-height: 67px;
  text-transform: uppercase;
  color: var(--accentColor2);
  text-align: center;
  margin-bottom: 30px;
}

.comments-bloc__coments-card-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.comments-bloc__coments-card {
  width: 100%;
  border: 1px solid var(--accentColor2);
  border-radius: 13px;
  box-shadow: 0px 0px 120px 0px rgba(10, 17, 53, 0.1);
}

.comments-bloc__coments-card-img {
  width: 100%;
  height: 250px;
  border-radius: 13px 13px 0 0;
}

.comments-bloc__coments-card-text-wrap {
  padding: 0 15px 15px;
}

.comments-bloc__coments-card-title {
  font-size: 19.2px;
  font-style: normal;
  font-weight: 600;
  line-height: 26.24px;
  letter-spacing: 0.96px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.comments-bloc__coments-card-text {
  font-size: 14.4px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.867px;
  letter-spacing: 0.48px;
  color: var(--customColor);
}

@media (min-width: 640px) {
  .comments-bloc__coments-card {
    width: 48%;
  }
}

@media (min-width: 768px) {
  .comments-bloc__coments {
    padding: 0 0 30px;
  }
  .comments-bloc__coments-title {
    font-size: 145px;
    line-height: 133px;
  }
}

@media (min-width: 960px) {
  .comments-bloc__coments-title {
    font-size: 200px;
    line-height: 178px;
  }
  .comments-bloc__coments-card {
    width: 23%;
  }
}

@media (min-width: 1440px) {
  .comments-bloc__coments-title {
    font-size: 256px;
    line-height: 230px;
  }
}
