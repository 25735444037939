.upload-image {
}

.upload-image__wrap {
  position: relative;
  display: flex;
  align-items: center;
  gap: 25px;
  width: 100%;
  background: rgba(192, 182, 255, 0.1);
  border-radius: 5px;
  padding: 15.5px 10px;
  margin-bottom: 20px;
  cursor: pointer;
}

.upload-image__img {
  width: auto;
  height: 25px;
}

.upload-image__img--video {
  height: 21px;
  padding-right: 2px;
  padding-left: 2px;
}

.upload-image__text {
  font-family: 'Montserrat';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: var(--customColor);
}

.upload-image__label {
  position: absolute;
  top: 0;
  left: 10px;
  transform: translateY(-50%);
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 400;
  color: var(--customColor);
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  background: #fff;
  padding: 0px 4px;
}

.upload-image__popover-wrap {
}

.upload-image__popover-wrap .MuiPaper-root {
  box-shadow: none;
  border: 1px solid var(--accentColor);
  border-radius: 0px 0px 8px 8px;
}

.upload-image__popover {
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 10px 20px;
}

.upload-image__popover-btn {
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--primaryColor);
  text-align: start;
  border-bottom: 1px solid var(--accentColor2);
  padding-bottom: 15px;
}

.upload-image__popover-label {
  font-family: 'Montserrat';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: var(--primaryColor);
  text-align: start;
  padding-top: 15px;
}
