.layout-user {
  position: relative;
}

.layout-user--wrpa {
  max-height: 100dvh;
}

.layout-user__sidenav {
  display: none;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  flex-direction: column;
  height: 100%;
  width: 110px;
  overflow-x: hidden;
  padding: 25px;
  border: 1px solid var(--accentColor2);
  border-radius: 0 12px 12px 0;
  transition: 0.5s;
}

.layout-user__sidenav--open {
  width: 275px;
  padding: 25px 40px;
}

.layout-user__sidenav-logo-wrap {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--accentColor2);
  margin-bottom: 30px;
}

.layout-user__sidenav-logo {
  visibility: hidden;
  width: 0;
  max-width: 0;
  height: 0;
  z-index: -2;
}

.layout-user__sidenav-logo--close {
  visibility: visible;
  width: 75px;
  max-width: 43px;
  height: 52px;
  min-height: 60px;
  margin-left: 4px;
}

.layout-user__sidenav-logo--open {
  width: 66px;
  max-width: 66px;
  height: auto;
  min-height: 60px;
  visibility: visible;
}

.layout-user__sidenav-link-wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.layout-user__sidenav-link {
  display: flex;
  align-items: center;
  gap: 20px;
  min-width: 50px;
  /* padding: 10px 8px; */
  border-radius: 10px;
  transition: 0.3s;
  border: 1px solid rgb(245 255 224);
}

.layout-user__sidenav-link:hover {
  border: 1px solid var(--highlightColor);
}

.layout-user__sidenav-link--active {
  /* background: var(--highlightColor); */
  /* border: 1px solid var(--highlightColor); */
  border-radius: 10px;
}

.layout-user__sidenav-link--active:hover {
  /* border: 1px solid var(--highlightColor) !important; */
}

.layout-user__sidenav-link:hover .layout-user__sidenav-link-text,
.layout-user__sidenav-link--active .layout-user__sidenav-link-text {
  color: var(--primaryColor);
}

.layout-user__sidenav-link-icon-wrap {
  padding: 10px 7px;
}

.layout-user__sidenav-link--active .layout-user__sidenav-link-icon-wrap {
  background: var(--highlightColor);
  border-radius: 8px;
}

.layout-user__sidenav-link-icon {
  width: 35px;
  min-width: 35px;
  height: auto;
}

.layout-user__sidenav-link-icon--width {
  /* width: 27px; */
  /* min-width: 27px; */
  height: auto;
  margin-left: 4px;
  margin-right: 4px;
}

.layout-user__sidenav-link-icon-path {
  fill: var(--customColor);
  transition: 0.3s;
}

.layout-user__sidenav-link:hover .layout-user__sidenav-link-icon-path,
.layout-user__sidenav-link-icon-path--active {
  fill: var(--primaryColor);
  transition: 0.3s;
}

.layout-user__sidenav-link-icon-exit {
  width: 28px;
  min-width: 28px;
  height: auto;
  margin-left: 9px;
}

.layout-user__sidenav-link-text {
  font-size: 20px;
  font-weight: 500;
  white-space: nowrap;
  color: var(--customColor);
  transition: 0.3s;
}

.layout-user__sidenav-link-text--close {
  opacity: 0;
  width: 0;
}

.layout-user__sidenav-link-text--exit {
  color: var(--primaryColor);
}

.layout-user__sidenav-btn-open-wrap {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.layout-user__sidenav-btn-open-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 25px;
  height: 20px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  transform: rotate(180deg);
}

.layout-user__sidenav-btn-open-span {
  width: 100%;
  height: 2px;
  background-color: var(--primaryColor);
  transition: 0.5s;
}

.layout-user__sidenav-btn-open-span--2 {
  width: 50%;
  align-self: flex-end;
  transition: 0.5s;
}

.layout-user__sidenav-btn-open-span--open {
  align-self: flex-start;
  transition: 0.5s;
}

.layout-user__sidenav-btn-open-menu--open {
  margin-right: 0px;
}

.layout-user__main {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  max-height: 100dvh;
  overflow: hidden;
}

#main {
  transition: margin-left 0.5s;
  padding: 0 15px;
}

.layout-user__modal-registration-text {
  max-width: 335px;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: center;
}

.layout-user__modal-registration-stripe-title {
  font-size: 16px;
  font-weight: 500;
  color: var(--primaryColor);
  align-self: flex-start;
}

.layout-user__modal-registration-stripe-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  height: 51px;
}

@media (min-width: 640px) {
  #main {
    padding: 20px;
  }
  .layout-user__sidenav {
    display: flex;
    padding: 20px 25px;
    background: rgb(245 255 224);
  }
  .layout-user__main {
    margin-left: 110px;
    padding-bottom: 0;
  }
  .layout-user__main--open {
    margin-left: 275px;
  }
}
